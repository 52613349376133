* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.rootContainer {
    display: flex;
    flex-direction: column;
    max-width: var(--desktop-width);
    margin: 0 auto;
}

footer {
    background-color: var(--color-backgroundgray);
    color: var(--color-white);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 6.5rem 3rem 4rem;
}

footer .footerTitle {
    font-family: var(--fonts-poppins-regular);
    font-size: 2.5rem;
    letter-spacing: 0.063rem;
    padding: 0 0 4.5rem;
}

footer > div > span {
    font-family: var(--fonts-poppins-bold);
}

.footerContainer {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    justify-content: space-evenly;
    padding: 2rem;
    column-gap: 1rem;
}

.footerwrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top:1.25rem;
    height: 7.5rem;
}

.info {
    display: flex;
    font-size: var(--venia-global-fontSize-100);
    letter-spacing: 0.063rem;
    line-height: 1.313rem;
}

.info p {
    font-weight: 700;
    margin-right: 0.313rem;
}
.infoContainer {
    margin: 1.25rem 0;
}

.contact {
    margin-top: auto;
    font-family: var(--fonts-poppins-regular);
    font-size: var(--venia-global-fontSize-300);
    text-decoration: underline;

    cursor: pointer;
}
.contact:hover {
    text-decoration: none;
}

.footerContainerInfo {
    display: grid;
    grid-auto-columns: auto;
    grid-gap: 1.75rem;
    justify-content: center;
}

.footerCustomerContent{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: var(--color-whitegray);
    padding-left: 9%;
    min-height:6rem ;
    color: var(--color-darkgray);
    font-family: var(--fonts-poppins-medium);
    font-size: 1rem;
    line-height: 2rem;
}

.footerCustomerContent a{
    margin-right: 1.5rem;
}

.footerContainerInfo > img {
    display: flex;
    justify-self: center;
    height: 6.313rem;

}

.footerContainerInfo > h4 {
    font-size: 1.375rem;
    font-family: var(--fonts-poppins-semibold);
    padding: 1rem 0 0;
}


/* NewsLetter */

.newsletter {
    background-color: var(--color-whitegray);
}

.newsletterHeader {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'infoRows signUp';
    justify-content: space-between;
    padding: 5rem 10.938rem 13.125rem;
    gap: 2rem;

}

.newsletterInfo {
    font-family: var(--fonts-poppins-regular);
    display: grid;
    grid-area: infoRows;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 6.25rem;
    width: fit-content;
}

.newsletterInfo > ul {
    font-size:0.9rem;
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    grid-gap: 0.625rem;
    list-style-type: none;
    line-height: 1.8rem;
    color: var(--color-base);
}

.newsletterInfo > ul > a {
    font-size: 1rem;
    font-weight: 300;
}

.newsletterInfo > ul > button {
    font-size: 1rem;
    font-weight: 300;
    text-align: start;
    font-family: var(--fonts-poppins-regular), serif;
}

.newsletterInfo p > span {
    font-size: var(--venia-global-fontSize-400);
    font-family: var(--fonts-poppins-bold);
}

.newsletterOuterContainer {
    grid-area: signUp;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.newsletterContainer {
    font-family: var(--fonts-poppins-regular);
    display: grid;
    grid-auto-rows: min-content;
    grid-auto-flow: row;
    grid-gap: 1.875rem;
    max-width: 34.5rem;
}

.newsletterContainer > h2 {
    font-size: var(--venia-global-fontSize-1000);
    color: var(--color-base);
}
.newsletterContainer > h2 > span {
    font-weight: 600;
}

.newsletterContainer > p {
    font-size: 1rem;
    letter-spacing: 0.063rem;
    font-weight: 400;
    line-height: 1.2;
    color: var(--color-base);
}

.newsLetterBtn {
    border: none;
    outline: none;
    color: var(--color-white);
    background-color: var(--color-red);
    white-space: nowrap;
    padding: 0px 3.313rem;
    font-family: var(--fonts-poppins-semibold);
    font-size: var(--venia-global-fontSize-400);
    border-top-right-radius: 0.313rem;
    border-bottom-right-radius: 0.313rem;
    cursor: pointer;
}
.newsLetterBtnContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.newsLetterInput {
    font-family: var(--fonts-poppins-regular);

    border: none;
    outline: none;
    width: 100%;
    height: 3.75rem;
    text-indent: 0.938rem;
    font-size: var(--venia-global-fontSize-200);
}

@media only screen and (max-width: 770px) {
    .newsletterHeader {
        align-items: center;
        text-align: center;
        justify-content: space-around;
        flex-direction: column-reverse;
    }
    .newsletterContainer > h2 {
        font-size: var(--venia-global-fontSize-700);
        line-height: 2.625rem;
    }

    .newsletterInfo > ul {
        text-align: start;
    }
    .newsletterInfo > ul > p {
        line-height: 1.563rem;
    }


    .newsLetterBtnContainer {
        padding-bottom: 1.125rem;
    }
    .newsLetterInput {
        height: 3.125rem;
    }
    .newsLetterBtn {
        padding: 0 1rem;
        display: flex;
        align-items: center;
    }
    .newsletterInfo > ul > a {
        font-size: var(--venia-global-fontSize-100);
        line-height: 1.313rem;
    }
    .newsletterInfo p > span {
    font-size: var(--venia-global-fontSize-200);
    }

    .footerCustomerContent{
       padding-top: 2rem;
       padding-bottom: 2rem;
       font-size: 1rem;
       line-height: 2.5rem;
    }
}

.socialImages {
    width: 3.75rem;
    padding: 0 0.313rem;
    margin: 0 0.625rem;
    vertical-align: sub;
}

.socialPayment {
    text-align: center;
}

/* Social Media and Payments */

.socialContainer {
    display: flex;
    padding: 3.35rem 10rem;
    align-items: center;
}

.socialImages {
    width: 3.625rem;
    height: 3.625rem;
    padding: 0 0.313rem;
    margin: 0 0.625rem;
    vertical-align: sub;
}

.cardsImages {
    margin: 0 0.625rem;
    width: 100%;
    max-width: 31.25rem;
}

.cardsImages + .cardsImages {
    display: none;
}

.socialPayment {
    display: flex;
    width: 100%;
    max-width: calc(100% - 19.5rem);
}

.socialMedia{
    grid-area: socialMedia;
    justify-self: flex-end;
    display: flex;
}

.footer {
    font-family: var(--fonts-poppins-regular);
    font-size:1rem;
    display: flex;
    justify-content: center;
    background-color: var(--color-lightgray);
    color: var(--color-footergray);
    padding: 1.5rem 0;
}

.socialMedia :global .social-media {
    justify-self: flex-end;
    display: flex;
}
.socialMedia > div {
    margin: 0;
}
.socialMedia :global .social-media a {
    display: block;
    margin: 0 1rem;
}
.socialMedia :global .social-media img {
    width: 5.625rem;
    padding: 0;
    vertical-align: sub;
}

@media only screen and (max-width: 1248px) {
    .newsletterHeader {
        padding: 3.125rem 6.25rem;
    }
    .socialContainer{
        padding: 1.875rem 5rem;

    }
    .newsletterInfo {
        width: auto;
        grid-column-gap: 3rem;
    }
}

@media only screen and (max-width: 1024px) {

    .footer {
        font-size:0.9rem;

    }
    footer > div{
        font-size: var(--venia-global-fontSize-400);
        padding: 0 0 0;
    }

    .newsletterOuterContainer{
        justify-content: flex-start;
    }

    .newsletterContainer{
        max-width: none;
    }
    .newsletterHeader {
        display: grid;
        grid-gap: 2.5rem;
        grid-template-columns: 1fr;
        grid-template-areas: 'signUp' 'infoRows';
        padding: 3.125rem 6.25rem 13.125rem;
    }

    .socialContainer {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        grid-auto-rows: min-content;
        grid-template-areas: "socialMedia" "socialPayment";
        grid-gap: 3.75rem;
        padding: 3.75rem 0 2.5rem 0;
    }

    .socialPayment {
        justify-self: center;
    }

    .socialMedia {
        justify-self: center;
    }
    .socialMedia :global .social-media {
        justify-self: center;

    }

    .footerContainer {
        display: grid;
        grid-gap: 5.625rem;
        grid-auto-flow: row;
        grid-auto-rows: min-content;
    }
}

@media only screen and (max-width: 767px) {
    .socialContainer {
        flex-wrap: wrap;
    }
    .socialPayment {
        max-width: 100%;
        text-align: center;
        padding: 0 1.5rem;
    }
    .cardsImages {
        margin: 0 auto;
    }
    .socialMedia {
        margin: 0 auto;
    }

    .socialMedia :global .social-media {
        margin: 0 auto;
    }

    .socialMedia :global .social-media img {
        width: 3.625rem;
    }

    .socialMedia :global .social-media a {
        margin: 0 0.5rem;
    }
}

@media only screen and (max-width: 512px) {
    .newsletterHeader {
        padding: 3.125rem 1.25rem;
    }

}
