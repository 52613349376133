.lineItemLabel {
    font-family: var(--fonts-poppins-regular);
    font-size: 1.25rem;
    justify-self: start;
    display: block;
    width: calc(100% - 95px);
}

.price {
    font-family: var(--fonts-poppins-regular);
    font-size: 1.25rem;
    align-items: center;
}

.dinnerware {
    display: flex;
    justify-content: space-between;
}
@media (max-width: 767px) {
    .lineItemLabel{
        font-size: 0.875rem;
    }

    .price{
        font-size: 0.875rem;
    }
}
