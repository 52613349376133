.outerContainer {
    z-index: 10;
    height: 70vh;
    width: 100%;
    overflow: hidden;
    padding-left: var(--lower-header-padding-side);
    position: absolute;
    animation: slideRight3 300ms forwards;
    background-color: var(--color-lightergray);
    left: 0;
    top: 100%;
    display: grid;
    grid-template-columns: minmax(200px, min-content) auto;
    overflow-y: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    border-top: 3px solid var(--color-footergray);
    border-bottom: 3px solid var(--color-footergray);
}


.outerContainer::-webkit-scrollbar {
    display: none;
}

.outerContainer > * {
    animation: appearSlowly 300ms forwards 300ms;
}

.outerContainerheader1 {
    composes: outerContainer;
    animation: slideRight3 300ms forwards;
    width: 100% !important;
    left: 0;
}

.outerContainerheader1 > * {
    animation: appearSlowly 300ms forwards 300ms;
}


.innerPublicityMenu{
    display: flex;
    flex-direction: column;
    padding: 2.5rem 1.5rem 1rem 0;
    overflow: auto;
}

.innerPublicityMenu::-webkit-scrollbar {
    display: none;
}

.innerPublicityMenu img {
    width: 100%;
    margin-bottom: 1rem;
}

.innerMenu {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(10%, 30%);
    justify-items: baseline;
    border-left: 3px solid var(--color-footergray);
    overflow: auto;
    padding: 2.5rem var(--lower-header-padding-side) 2.5rem 1.5rem;
}

.innerMenu::-webkit-scrollbar {
    width: 10px;
}

.innerMenu::-webkit-scrollbar-track {
    background: transparent;

}

.innerMenu::-webkit-scrollbar-thumb {
    background-color: var(--color-gray);
    border-radius: 2rem;
    transition: 300ms;
}

.innerMenu::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-base);
}

.outerContainer > * {
    opacity: 0;
}


.column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
}


.item {
    margin: 0.5rem 0;
    font-family: var(--fonts-poppins-regular);
    font-size: 1rem;
    color: var(--color-gray);
    align-self: start;
    width: 100%;
}



.title {
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.25rem;
    color: var(--color-darkgray);
    min-height: 40px;
    margin: 0 0 0.5rem;
}


.announceText {
    composes: title;
    font-size: 2rem;
    margin-bottom: 2.25rem;
    text-decoration: none !important;
}



.title:hover, .link:hover {
    text-decoration: underline;
}


.shopNow {
    font-family: var(--fonts-poppins-regular);
    font-size: 1.25rem;
    color: var(--color-backgroundgray);
    position: relative;
    margin: 0.85rem 3rem 0.85rem 0;
    width: fit-content;
    text-decoration: none;

}

.shopNow:after {
    width: 0.75rem;
    height: 0.75rem;
    content: '';
    mask: url('../../../venia-static/icons/arrow.svg') no-repeat 50% 50%;
    -webkit-mask: url('../../../venia-static/icons/arrow.svg') no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
    position: absolute;
    top: 25%;
    right: -1.5rem;
    transition: 200ms;
    transform: translateX(0px) rotate(90deg);
    background-color: var(--color-gray);;
}

.shopNow:hover:after {
    transform: translateX(3px) rotate(90deg);
}

.hotDealsButton {
    composes: shopNow;
    color: var(--color-red);
    font-family: var(--fonts-poppins-semibold);
}

.hotDealsButton::after {
    background-color: var(--color-red);

}

.shopall {
    composes: shopNow;
    font-family: var(--fonts-poppins-semibold);
    font-size: 1rem;
    color: var(--color-backgroundgray);

}

.shopall::after {
    top: 2px;
}


.newInButton {
    composes: shopNow;
}

.shopAllCategory {
    composes: shopNow;
}

.pubText {
    color: var(--color-base);
    font-family: var(--fonts-poppins-bold);
    font-size: 1.25rem;
    letter-spacing: 0.001rem;
    line-height: 1.5rem;
    max-width: 100%;
}


.actionsContainer {
    display: grid;
    margin-top: 3.125rem;
    grid-auto-flow: column;
    justify-items: start;
}

.actionsContainerMiddle {
    height: fit-content;
    display: grid;
    row-gap: 1rem;

}

.actionsContainer > a{
    margin-top: 0;
}



@keyframes slideRight3 {
    from {
        width: 0;
    }
    to {
        width: calc(100vw);
    }
}


@keyframes appearSlowly {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
