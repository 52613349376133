.root {
    background-color: white;
    font-size: 0.8125rem;
    position: absolute;
    top: 100%;
    z-index: 1000;
    left: 0;
    width: 100%;
    height: calc(100vh - 220px);
    border-bottom: 1px solid var(--color-lightergray);
}
.innerRoot {
    position: relative;
    max-width: 100%;
    width: 80%;
    margin: 4rem auto;
    height: inherit;
}


.closeSearchContainer {
    position: absolute;
    right: 2rem;
    top: 1rem;
}

.innerContent {
    height: calc(100% - 4rem);
    overflow-y: auto;
}

.innerContent:after {
    display: block;
}

.leftAutocomplete {
    position: relative;
    width: 30%;
    float: left;
    padding-right: 2rem;
}

.rightAutocomplete {
    position: relative;
    width: 66.666%;
    float: right;
}

.items {
    position: relative;
    padding: 0;
    display: grid;
    gap: 2rem 1.5rem;
    grid-template-columns: repeat(2, 1fr);
}

.item {
    width: 100%;
    display: grid;
    align-items: center;
}

.content {
    display: grid;
    grid-template-columns: 30% auto;
    align-items: center;
    justify-content: left;
}

.imageContainer {
    position: relative;
    display: block;
    height: 0;
    padding: 0 0 100%;
    overflow: hidden;
    width: 100%;
}

.itemContent {
    padding: 0 1.125rem;
    text-align: left;
}

.itemContent .name {
    display: block;
    line-height: 150%;
    color: var(--color-base);
    font-size: 1rem;
    font-family: var(--fonts-poppins-regular);
    padding: 0 0 0.5rem;
}

.price span {
    color: var(--color-base);
    font-size: 1.5rem;
    font-family: var(--fonts-poppins-semibold);
}
.priceRegular span {
    position: relative;
    color: var(--color-base);
    font-size: 1rem;
    font-family: var(--fonts-poppins-semibold);
    padding: 0 0 0.25rem;
}

.priceRabais {
    position: relative;
}

.oldPrice {
    position: relative;
    display: inline-block;
    text-decoration: line-through;
    padding: 0 0 0.5rem;
    color: var(--color-darkgray);
}
.oldPrice > span {
    color: var(--color-darkgray);
    font-size: 0.875rem;
    font-family: var(--fonts-poppins-regular);
    padding: 0 0 0.25rem;
}

.newPrice > span {
    position: relative;
    color: var(--color-red);
    font-size: 1rem;
    font-family: var(--fonts-poppins-semibold);
    padding: 0 0 0.25rem;
}

.newPrice .percentOff {
    color: var(--color-darkgray);
    font-size: 1rem;
    display: inline-block;
    padding: 0 0 0 1rem;
}

.ratingSection {
    display: block;
    width: 100%;
}
.rating {
    position: relative;
    width: 5rem;
    height: 0.75rem;
    margin: 0.5rem 0 0;
}
.defaultRating {
    position: relative;
    background-image: url(~@magento/venia-ui/venia-static/icons/star.svg);
    background-size: 1rem 0.75rem;
    height: 0.75rem;
    opacity: 0.15;
}
.scoreRating {
    position: absolute;
    background-image: url(~@magento/venia-ui/venia-static/icons/star.svg);
    background-size: 1rem 0.75rem;
    height: 0.75rem;
    top: 0;
    left: 0;
}

.itemContent .name b {
    font-family: var(--fonts-poppins-semibold);
}
.imageContainer img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.labelTitle {
    position: relative;
    color: var(--color-lightgray);
    font-size: 1.125rem;
    font-family: var(--fonts-poppins-semibold);
    padding: 0 0 1rem;
}

.itemsSuggestions {
    position: relative;
    padding: 0 0 3.5rem;
    max-width: 15rem;
}

.suggestItem {
    position: relative;
    padding: 0.5rem 0;
}

.suggestItem button {
    text-align: left;
}

.suggestLink span {
    position: relative;
    color: var(--color-gray);
    font-size: 0.875rem;
    line-height: 130%;
    font-family: var(--fonts-poppins-regular);
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.suggestLink span b {
    color: var(--color-base);
    font-family: var(--fonts-poppins-semibold);
}

/* width */
.innerRoot::-webkit-scrollbar {
    width: 0.4rem;
}

/* Track */
.innerRoot::-webkit-scrollbar-track {
    background-color: var(--color-whitegray);
    border-radius: 0.25rem;
}

/* Handle */
.innerRoot::-webkit-scrollbar-thumb {
    background-color: var(--color-base);
    border-radius: 0.75rem;
}

/* Handle on hover */
.innerRoot::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-base);
}

.closeContainer {
    grid-area: closeBar;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
}

.searchesContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, min-content);
    grid-gap: 50px;
    margin-left: 70px;
}



.searchContainer {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    grid-gap: 10px;
}

.searchTitle {
    font-size: 18px;
    color: var(--color-lightgray);
    font-family: var(--fonts-poppins-semibold);
    font-weight: bold;
}

.result {
    cursor: pointer;
    font-size: 14px;
    font-family: var(--fonts-poppins-regular);
    color: var(--color-gray);
}

.trendingContainer {
    display: flex;
    flex-direction: column;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: var(--color-lightgray);
    font-family: var(--fonts-poppins-semibold);
    font-size: 18px;
    letter-spacing: 0.68px;
    font-weight: bold;
    margin-bottom: 10px;
}

.productsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, min-content);
    grid-gap: 25px;
}

.productContainer {
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(2, min-content);
    grid-gap: 10px;
}

.imageProduct {
    width: 200px;
    height: 170px;
}

.description {
    display: grid;
    grid-auto-rows: min-content;
    grid-gap: 5px;
    padding: 15px;
}

.productTitle {
    line-height: 20px;
}

.searchedWord {
    font-weight: bold;
    margin: 0 5px;
}

.discount {
    text-decoration: line-through;
}

.discountContainer {
    display: grid;
    grid-template-columns: repeat(2, min-content);
    grid-gap: 10px;
}

.discountPrice {
    font-weight: bold;
    color: var(--color-red);
}

.savePrice {
    font-weight: 600;
    white-space: nowrap;
}

.star {
    height: 12px;
    width: 12px;
}

.noStar {
    height: 12px;
    width: 12px;
    fill: gray;
}

.close_icon {
    cursor: pointer;
}

.headerTitle {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 10px;
}

.headerTitle > .titleContainer {
    font-size: 24px;
}

.shopAll {
    position: relative;
    padding: 0.875rem 0.25rem;
    color: var(--color-white);
    font-size: 1.25rem;
    font-family: var(--fonts-poppins-regular);
    background-color: var(--color-red);
    border-radius: 1.875rem;
    width: 100%;
    max-width: 12.25rem;
    margin: 0 0 1rem;
}

.reviews {
    display: block;
    position: absolute;
    left: 5.75rem;
    white-space: nowrap;
    top: 0;
    text-decoration: underline;
}



@media (max-width: 1248px) {
    .root {
        top: 100%;
    }
    .close {
        right: 1rem;
    }
    .innerRoot {
        overflow-y: scroll;
        width: 100%;
        padding: 0.5rem 1.25rem;
    }
}

@media (max-width: 850px) {
    .root {
        height: 80vh;
        overflow-y: scroll;
    }
    .innerRoot {
        overflow: hidden;
        margin-top: -2rem;
    }
    .closeSearchContainer {
        padding-right: 0.5rem;
    }
    .closeSearchContainer button {
        z-index: 1;
    }
    .shopAll {
        position: relative;
        padding: 0.85rem 0.25rem;
        color: var(--color-white);
        font-size: 0.75rem;
        font-family: var(--fonts-poppins-regular);
        background-color: var(--color-red);
        border-radius: 1.875rem;
        width: 100%;
        max-width: 100%;
        margin: 3rem 0;
    }

    .content {
        display: block;
    }

    .imageContainer {
        width: 100%;
        padding: 0 0 82%;
    }
    .itemContent {
        width: 100%;
        padding: 1rem 0 0;
    }

    .leftAutocomplete {
        width: 100%;
        float: none;
        padding: 1.5rem 0;
    }
    .rightAutocomplete {
        width: 100%;
        float: none;
        padding: 0;
    }

    .price span,
    .oldPrice > span,
    .itemContent .name {
        font-size: 0.75rem;
    }
    .priceRegular,
    .oldPrice,
    .newPrice {
        padding: 0 0 0.25rem;
    }
    .priceRegular {
        padding-top: 1rem;
    }

    .priceRegular span,
    .newPrice .percentOff,
    .newPrice > span {
        font-size: 0.875rem;
    }

    .rating {
        width: 3.375rem;
        height: 0.5rem;
        margin: 0.5rem 0 0;
        font-size: 0.625rem;
    }
    .defaultRating {
        background-size: 0.675rem 0.5rem;
        height: 0.5rem;
    }
    .scoreRating {
        background-size: 0.675rem 0.5rem;
        height: 0.5rem;
    }

    .reviews {
        left: 3.75rem;
    }

    .itemContent .name {
        font-size: 0.85rem;
    }
}
