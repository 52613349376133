.button{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-gap: 1rem;
    align-items: center;
    justify-content: center;
    min-height: 3.5rem;
    border-radius: 1.875rem;
    background-color: var(--color-red);
    color: var(--color-white);
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.25rem;
}

.button:disabled{
    opacity: 0.3;
}

.button > img{
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
    brightness(102%) contrast(102%);
    margin-bottom: 0.2rem;
}

@media (max-width: 767px) {

    .button{
        padding: 0 1rem;
        min-height: unset;
    }

}
