.root {
    width: 100%;
}

.form {
    padding: 0;
    align-items: center;
    display: grid;
    justify-items: stretch;
    width: 100%;
}

.container {
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
}

.search {
    width: 100%;
}

.buttonClass {
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 55px;
    z-index: 1;
    border-radius: 0 25px 25px 0;
}
