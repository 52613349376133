.root {
    position: sticky;
    top: 0;
    left: 0;
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    z-index: 12;
    background-color: var(--color-white);
    width: 100%;
    transition: 300ms;
}

.upperHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: var(--color-whitegray);
    align-items: center;
    height: 3.125rem;
    padding: 0 var(--lower-header-padding-side);
    font-size: 1.125rem;
}

.StockesLogo {
    height: 4.375rem;
}

.middleLogo {
    height: 4.375rem;
    max-width: 13.125rem;
}
.ThinkKitchenContainer {
    width: 25%;
}

.shippingContainer {
    display: flex;
    align-items: center;
}

.shippingContainer > * {
    font-size: 1.125rem;
    font-weight: bold;
    color: var(--color-darkgray);
    letter-spacing: 0.054rem;
}

.shippingContainer > div > button {
    font-family: var(--fonts-poppins-regular);
    font-weight: 500;
    letter-spacing: 0.054rem;
    border-bottom: 0.063rem solid black;
}

.shippingContainer > div {
    margin: 0 0.625rem;
}

.seperator {
    font-weight: normal;
}

.middleHeader {
    display: grid;
    width: 100%;
    grid-auto-flow: column;
    grid-template-columns: 1fr 2fr 1fr;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    padding: 1rem var(--lower-header-padding-side) 1rem;
    grid-gap: 1rem;
    z-index: 2;
}

.storeSchedules {
    position: relative;
    display: grid;
    grid-template-areas: 'name button' 'time button';
    grid-template-columns: 1fr min-content;
    grid-gap: 0 0.313rem;
    white-space: nowrap;
    font-size: 1rem;
    letter-spacing: 0.049rem;
}

.locationName {
    grid-area: name;
    color: var(--color-lightgray);
    letter-spacing: 0.049rem;
}

.locationTime {
    grid-area: time;
    color: var(--color-lightgray);
    font-weight: bold;
}

.middleHeader > button {
    grid-area: button;
}

.lowerHeader {
    display: flex;
    justify-content: space-between;
    padding: 0 var(--lower-header-padding-side) 0;
    align-items: center;
    width: 100%;
    position: relative;
    border-top: 3px solid var(--color-footergray);
    border-bottom: 3px solid var(--color-footergray);
}

.lowerHeader > div {
    cursor: pointer;
}

.lowerHeaderNone {
    display: none;
}

.MenuIcon {
    color: var(--color-red);
    align-self: center;
}

.hotDeals {
    color: white;
    background-color: var(--color-red);
    padding: 0.625rem 1.875rem;
    border-radius: 1.875rem;
}
.newIn {
    color: var(--color-lightgray);
    background-color: var(--color-white);
    padding: 0.625rem 1.875rem;
    border-radius: 1.875rem;
    border: 0.063rem solid var(--color-lightgray);
    margin: 1.25rem 0;
}
.headersNavigationThirdRow {
    display: flex;
    flex-direction: column;
}

.headersNavigationFourthRow > img {
    height: 18.75rem;
    width: 18.75rem;
}

.SignIn {
    color: var(--color-gray);
    font-size: 0.75rem;
}
.userCart {
    display: flex;
    margin-left: 0.875rem;
    align-items: center;
}

.switchersContainer {
    display: none;
}



.open {
    composes: root;
}

.closed {
    composes: root;
}

.toolbar {
    z-index: 3;
    align-content: center;
    align-items: center;
    display: grid;
    grid-template-areas: 'primary title secondary';
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 3rem;
    justify-items: center;
    max-width: var(--venia-global-maxWidth);
    min-height: var(--venia-global-header-minHeight);
    padding: 0 0.5rem;
    width: 100%;
}


.logo {
    grid-area: title;
}

.primaryActions {
    grid-area: primary;
    justify-self: start;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
}

.secondaryActions {
    grid-area: secondary;
    width: max-content;
    display: grid;
    grid-auto-flow: column;
    justify-items: end;
    align-items: center;
    justify-self: end;
}


.searchFallback {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
    padding: 0 1rem;
}

.input {
    composes: input from '../TextInput/textInput.css';
    max-width: 24rem;
}

.loader,
.loader:before,
.loader:after {
    --dot-size: 2em;
    --dot-font-size: 6px;
    --dot-shadow-offset: calc(-1 * var(--dot-size) + var(--dot-font-size));
    border-radius: 50%;
    width: var(--dot-size);
    height: var(--dot-size);
    animation: pulse 1.8s infinite ease-in-out;
    animation-fill-mode: both;
}

.loader {
    color: rgb(var(--venia-global-color-gray-dark));
    font-size: var(--dot-font-size);
    margin: var(--dot-shadow-offset) auto 0;
    position: relative;
    transform: translateZ(0);
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    content: '';
    position: absolute;
    top: 0;
}

.loader:before {
    color: rgb(var(--venia-global-color-gray));
    left: -3.5em;
    animation-delay: -0.32s;
}

.loader:after {
    color: rgb(var(--venia-global-color-gray-darker));
    left: 3.5em;
}

.menuContainer {
    position: absolute;
    top: 3.55rem;
    left: calc((var(--lower-header-padding-side) + 1.125rem) * -1);
    background-color: var(--color-whitegrayish);
    z-index: 5;
    animation: slideTopDown 0.5s forwards;
}

@keyframes slideTopDown {
    from {
        width: 0;
    }
    to {
        width: 23.75rem;
    }
}
.locationArrow {
    margin-left: 0.625rem;
    height: 1.063rem;
    width: 1.063rem;
    transition: all 0.3s;
    transform: rotate(180deg);
}

.locationArrowDown {
    margin-left: 0.625rem;
    height: 1.063rem;
    width: 1.063rem;
    transform: rotate(0deg);
    transition: all 0.3s;
}

.mainMenu {
    display: grid;
    grid-auto-rows: 5rem;
    grid-template-columns: 1fr;
    align-items: center;
    width: 100%;
    font-family: var(--fonts-poppins-semibold);
}

.mainMenu > .subMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3.125rem 0 4.688rem;
    height: 100%;
}

.mainMenu > .subMenu:hover {
    background-color: white;
}

.mainMenu > .subMenu::after {
    content: url('../../../venia-static/icons/arrow.svg');
    opacity: 0;
    transition: all 0.3s;
}

.mainMenu > .subMenu:hover::after {
    content: url('../../../venia-static/icons/arrow.svg');
    opacity: 1;
    transform: rotate(90deg);
}

.subMenu {
    position: relative;
    animation: appearSlowly 2s forwards;
}

@keyframes appearSlowly {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.innerMenu {
    position: absolute;
    left: 23.75rem;
    top: 0rem;
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    grid-column-gap: 7.188rem;
    grid-row-gap: 1.438rem;
    max-height: 35rem;
    white-space: nowrap;
    font-weight: normal;
    animation: slideRight 0.5s forwards;
    background-color: white;
}

.innerMenu > * {
    cursor: pointer;
}

@keyframes slideRight {
    from {
        width: 0;
        padding: 1.25rem 0;
    }
    to {
        width: auto;
        padding: 1.25rem 4.688rem;
    }
}

.title {
    font-size: 1rem;
    font-family: var(--fonts-poppins-semibold);
    font-weight: bold;
}

.option {
    font-size: 0.875rem;
    letter-spacing: 0.043rem;
    font-family: var(--fonts-poppins-regular);
}

.shopall {
    text-decoration: underline;
}



@keyframes pulse {
    0%,
    80%,
    100% {
        box-shadow: 0 var(--dot-size) 0 -1.3em;
    }
    40% {
        box-shadow: 0 var(--dot-size) 0 0;
    }
}

.optionImg {
    height: 21.25rem;
    width: 28.125rem;
    display: grid;
    grid-row: 1/10;
    grid-column: 4/5;
}

.redbutton {
    background-color: var(--color-red);
    color: white;
    letter-spacing: 0.061rem;
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.25rem;
    font-weight: bold;
    padding: 0.938rem 1.875rem;
    border-radius: 1.875rem;
}

.whitebutton {
    letter-spacing: 0.061rem;
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.25rem;
    padding: 0.938rem 1.875rem;
    border-radius: 1.875rem;
    border: 0.063rem solid var(--color-backgroundgray);
}

.shopallcategory {
    text-decoration: underline;
}

.pubnew {
    display: flex;
    justify-self: self-start;
    font-size: 1.125rem;
}

.pubtitle {
    display: flex;
    justify-self: self-start;
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.25rem;
    font-weight: bold;
}

.pubshop {
    display: flex;
    justify-self: self-start;
    text-decoration: underline;
    font-size: 1.125rem;
}


.headerIconContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
}

.headerIcons {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    padding: 0 0.625rem;
}

.headerIcons > p {
    text-align: center;
    margin-top: 0.313rem;
    font-family: var(--fonts-poppins-medium);
    font-size: 1rem;
    color: var(--color-lightgray);
}


.navigationHeaders {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(min-content, 1fr);
    text-align: center;
    grid-gap: 0.313rem;
    font-size: 1.25rem;
    font-weight: 500;
}

.navigationHeaders > * {
    font-family: var(--fonts-poppins-medium);
    font-weight: normal;
    font-size: 1.125rem;
    letter-spacing: 0.061rem;
}

.navigationHeadersSales {
    color: var(--color-red);
    font-family: var(--fonts-poppins-medium);
    letter-spacing: 0.061rem;
}
.navigationHeadersInspiration {
    position: relative;
    display: flex;
    align-items: flex-start;
    font-size: 1.25rem;
    color: var(--color-lightgray);
    font-family: var(--fonts-bornready-regular);
}

.tab {
    position: relative;
    display: flex;
    align-items: flex-start;
    font-size: 1.25rem;
    color: var(--color-lightgray);
}




.mainMenuTextred::after {
    background-color: var(--color-red);
}

.mainMenuText {
    display: grid;
    align-items: center;
    justify-items: center;
    font-family: var(--fonts-poppins-medium);
    font-size: 1rem;
    height: 3rem;
    padding: 0 1rem;
}

.mainMenuSeparator {
    width: 3px;
    height: 3rem;
    background-color: var(--color-footergray);
}

.mainMenuText:first-child {
    padding-left: 0;
}

.mainMenuText:last-child {
    padding-right: 0;
}


.mainMenuTextred {
    composes: mainMenuText;
    color: var(--color-red);
}

.mainMenuTextitalic {
    composes: mainMenuText;
    font-family: var(--fonts-bornready-regular);
    color: var(--color-lightgray);
    font-size: 1.2rem;
    /*because font size is bigger, to align it with bottom*/
    margin-top: -0.1rem;
}
.expander {
    width: 100%;
}

.navigationHeadersContainer {
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    cursor: pointer;

}

.menusContainer {
    left: 0;
    right: 0;
    top: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
}
