.root {
    margin: 1rem;
}

.content h2 {
    margin-bottom: 2rem;
    text-align: center;
    text-transform: uppercase;
}

.content img {
    display: block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
}
