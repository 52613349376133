.contactUsContainer {
    max-width: 90%;
    margin: 0 auto;
}
.actionsContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 6rem;
    padding-bottom: 6rem;
    border-bottom: 1px solid var(--color-filter);
}
.actionContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-gap: 2rem;
    justify-items: center;
}

.actionContainer > p {
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.2rem;
}

.actionContainer > button {
    font-family: var(--fonts-poppins-regular);
    font-size: 1rem;
    text-decoration: underline;
    text-underline-offset: 0.4rem;
}

.emailUsContainer {
    margin: 6rem auto;
    max-width: 56.875rem;
}

.emailUsHeader {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-gap: 2rem;
    align-items: center;
    justify-items: center;
    margin-bottom: 4rem;
}

.emailUsHeader h1 {
    font-family: var(--fonts-poppins-medium);
    font-size: 2.5rem;
    color: var(--color-backgroundgray);
}

.emailUsHeader p {
    font-family: var(--fonts-poppins-regular);
    font-size: 1rem;
    color: var(--color-gray-text);
}

.formContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-gap: 2rem;
}

.formContainer select {
    color: var(--color-text);
}

.rowInput {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
}

.selectFilesContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
}

.selectFilesContainer p {
    color: var(--color-backgroundgray);
    font-family: var(--fonts-poppins-medium);
    font-size: 0.875rem;
}

.selectFilesButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 11rem;
    border-radius: 1.875rem;
    border: 1px solid var(--color-base);
    padding: 0.5rem 1rem 0.5rem 1.5rem;
    font-family: var(--fonts-poppins-semibold);
    font-size: 0.875rem;
    margin-top: 1rem;
}

.textAreaContainer {
    width: 100%;
}

.textAreaContainer p {
    color: var(--color-backgroundgray);
    font-family: var(--fonts-poppins-medium);
    font-size: 0.875rem;
    margin-bottom: 0.875rem;
}

.textAreaContainer p::after {
    content: '*';
    color: var(--color-red);
}

.textArea {
    width: 100%;
    height: 11.75rem;
    border: 0.125rem solid var(--color-lightergray);
    padding: 1rem 1.5rem;
    font-family: var(--fonts-poppins-regular);
    font-size: 0.875rem;
}

.buttonContainer {
    display: flex;
    justify-content: center;
}
.buttonContainer button {
    padding: 1rem 0;
    font-size: 1.25rem;
    max-width: 15rem;
}

.recaptchaContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.downloadedFilesContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    grid-row-gap: 2rem;
    grid-auto-flow: row;
    margin-top: 1rem;
}

.downloadedContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.125rem solid var(--color-lightergray);
    padding: 1rem 1.5rem;
}

.downloadedContainer p {
    max-width: 70%;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media screen and (max-width: 767px) {
    .actionsContainer {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        grid-gap: 4rem;
        align-items: center;
        justify-content: space-around;
        margin-top: 6rem;
        padding-bottom: 6rem;
        border-bottom: 1px solid var(--color-filter);
    }

    .actionContainer img {
        width: 5rem;
        height: 5rem;
    }

    .emailUsHeader h1 {
        font-size: 1.25rem;
    }

    .emailUsHeader p {
        font-size: 0.75rem;
    }

    .buttonContainer button {
        font-size: 0.75rem;
        height: 2.5rem;
        max-width: 20rem;
    }

    .rowInput {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        grid-gap: 2rem;
    }

    .downloadedFilesContainer {
        grid-template-columns: 1fr;
        grid-gap: 2rem;
    }
}
