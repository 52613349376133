@import './tokens.css';

:global(*) {
    box-sizing: border-box;
}

:global(html) {
    background-color: rgb(var(--venia-global-color-gray-50));
    font-size: 100%;
    font-weight: var(--venia-global-fontWeight-normal);
    line-height: var(--venia-global-lineHeight-100);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

/*DONT REMOVE IT!!!!!!!!!!!!!!!!!!*/
/*this has no influence if not specifically using scroll lock*/
:global(html[data-scroll-lock='true'] body) {
    overflow: hidden;
}


:global(body),
:global(button),
:global(input),
:global(select),
:global(textarea) {
    color: rgb(var(--venia-global-color-text));
    font-family: var(--venia-global-fontFamily-sansSerif);
    outline: none;
}

:global(body) {
    background-color: rgb(var(--venia-global-color-gray-50));
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(h5),
:global(h6) {
    font-size: var(--venia-global-fontSize-200);
    font-weight: var(--venia-global-fontWeight-normal);
    margin: 0;
}

:global(h1) {
    font-size: var(--venia-global-fontSize-600);
}

:global(h2) {
    font-size: var(--venia-global-fontSize-400);
}

:global(a) {
    color: currentColor;
    text-decoration: none;
}

:global(p) {
    margin: 0;
}

:global(dl),
:global(ol),
:global(ul) {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

:global(dd),
:global(dt) {
    margin: 0;
}

:global(button) {
    background: none;
    border: 0;
    cursor: pointer;
    font-size: 100%;
    padding: 0;
    touch-action: manipulation;
    user-select: none;
    -webkit-appearance: none;
}

:global(button:disabled) {
    cursor: default;
    touch-action: none;
}

button {
    background-color: transparent;
    color: currentColor;
    cursor: pointer;
    outline: none;
    box-shadow: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-focus: ignore;
    user-select: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

button::-moz-focus-inner {
    border: 0;
}

/*because paypal index is overkill*/
:global(.paypal-buttons) {
    z-index: 1 !important;
}
