.upperHeader {
    display: grid;
    grid-auto-flow: column;
    width: 100%;
    justify-content: space-between;
    background-color: var(--color-whitegray);
    align-items: center;
    padding: 0 var(--lower-header-padding-side);
    font-size: 1.125rem;
}

.backToCart {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    text-decoration: underline;
    text-underline-offset: 0.2rem;
}

.rightNavigation {
    font-family: var(--fonts-poppins-regular);
    display: flex;
    color: var(--color-gray);
    font-size: 1rem;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    gap: 3rem;
}

.secureCheckout {
    display: grid;
    grid-template-columns: min-content max-content;
    justify-content: flex-end;
    grid-gap: 1rem;
    align-items: center;
}

.rightNavigation > * {
    cursor: pointer;
}

.secureCheckout p {
    font-size: 1.125rem;
    color: var(--color-base);
}



.storesButton {
    font-family: var(--fonts-poppins-regular);
    display: flex;
    color: var(--color-gray);
    gap: 2.5rem;
    font-size: 1rem;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 767px) {
    .upperHeader {
        padding: 0.2rem 0 0.2rem 0;
        height: auto;
        display: flex;
        flex-direction: row;
    }
    .upperHeader > p{
      margin: 0;
    }
    .upperHeader > button{
        margin-left: 0.2rem;
        margin-top: 0.1rem;
      }
}

.storeSchedules {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    padding: 0.5rem 0;
    cursor: pointer;
}

.locationName {
    font-size: 1rem;
    margin: 0 1rem;
    font-family: var(--fonts-poppins-regular);
}

.upperHeaderV1 {
    composes: upperHeader;
    padding: 0 var(--lower-header-padding-side);
}
