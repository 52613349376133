.page {
    padding: 3rem 9rem;
}
.pageBreadcrumbs {
    font-family: var(--fonts-poppins-semibold);
    font-size: 0.85rem;
}
.pageBreadcrumbs span {
    color: var(--color-link);
    margin-right: 0.5rem;
}
.pageBreadcrumbs .currentBreadcrumb {
    color: var(--color-current-breadcrumb);
    margin-right: 0;
}
.pageTitle {
    font-size: 3rem;
    font-family: var(--fonts-poppins-semibold);
    color: var(--color-black);
    padding: 4rem 0;
    border-bottom: 3px solid var(--color-lightergray);
    margin-bottom: 2rem;
    text-transform: uppercase;
}

.storeLocatorModalOverlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 11;
    overflow-x: auto;
    display: grid;
}

.autocompleteContainer {
    display: block;
    position: relative;
}

.autocompletePopup {
    position: absolute;
    top: 100%;
    background-color: white;
    margin-left: 1rem;
    z-index: 3;

    box-shadow: 0 0 5px 1px grey;
}

.autocompletePlace {
    padding: 0.5rem;
    cursor: pointer;
}

.storeLocatorModal {
    background-color: var(--color-white);
    width: 100%;
    margin: auto;
    padding-left: 2.125rem;
    padding-top: 1.375rem;
    position: relative;
}

.pageStoreLocatorModal {
    composes: storeLocatorModal;
    padding-left: 0;
    padding-top: 0;
}

.openStoreLocatorModal {
    composes: storeLocatorModal;
    animation: openStoreLocatorModal 0.5s forwards;
}

@keyframes openStoreLocatorModal {
    from {
        transform: translateY(-700px);
    }
    to {
        transform: translateY(0);
    }
}

.modalCloseBtn {
    display: grid;
    align-items: center;
    justify-items: center;
    position: sticky;
    position: -webkit-sticky;
    top: 1rem;
    float: right;
    margin-right: 1rem;
    border-radius: 100px;
    z-index: 100;
    width: 3rem;
    height: 3rem;
    background-color: white;
    cursor: pointer;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 2.125rem;
    position: relative;
}

.header > div {
    color: var(--color-black);
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.875rem;
    letter-spacing: 0.091rem;
    margin-bottom: 0.875rem;
}

.inputsContainer {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.inputLabelLocation {
    width: 30.5rem;
    margin-right: 1.563rem;
}

.inputLabelLocation .label {
    margin-bottom: 0.875rem;
    font-family: var(--fonts-poppins-semibold);
    font-size: 1rem;
}

.input {
    composes: input from '../../Field/field.css';
    height: 3.125rem;
    font-size: 1rem;
    padding-left: 1.875rem;
    border: 0.063rem solid var(--color-base);
}

.inputKM {
    composes: input from '../../Field/field.css';
    height: 3.125rem;
    font-size: 1rem;
    padding-left: 1.875rem;
    border: 0.063rem solid var(--color-base);
    display: flex;
    align-items: center;
    font-family: var(--fonts-poppins-regular);
}

.inputKM::placeholder {
    font-size: 1rem;
}

.input::placeholder {
    font-size: 1rem;
}

.inputLabelDistance {
    width: 12.5rem;
    margin-right: 2.125rem;
}

.inputLabelDistance .label {
    margin-bottom: 0.875rem;
    font-family: var(--fonts-poppins-semibold);
    font-size: 1rem;
}

.findAStoreButton {
    align-self: flex-end;
    height: 3.125rem;
    min-width: 12.188rem;
    padding: 0 1rem;
    border-radius: 1.563rem;
    background-color: var(--color-red);
    color: var(--color-white);
    font-family: var(--fonts-poppins-semibold);
    font-size: 1rem;
    letter-spacing: 0.048rem;
    margin-right: 1.875rem;
}

.resetButton {
    align-self: flex-end;
    color: var(--color-clear-filters);
    font-family: var(--fonts-poppins-regular);
    text-underline-offset: 0.125rem;
    font-size: 1rem;
    letter-spacing: 0.001rem;
    padding-bottom: 0.75rem;
    text-decoration: underline;
}

.listOfStores {
    margin: 2rem 0;
    color: var(--color-backgroundgray);
    font-family: var(--fonts-poppins-semibold);
    font-size: 1rem;
    letter-spacing: 0.054rem;
}

.listOfStoresLabel {
    margin: 1rem 0;
}

.toggleContainer {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-items: center;
    margin: 2rem 0 1rem;
}

.toggleLabel{
    font-family: var(--fonts-poppins-semibold);
    padding-left: 0.5rem;
}

.storesMapContainer {
    display: grid;
    grid-template-columns: repeat(2, minmax(28.125rem, 1fr));
    grid-template-rows: 1fr;
    height: 36.375rem;
}

.storesContainer {
    padding: 1.875rem 1.5rem 0 0;
}

.storesList {
    height: 30.5rem;
    width: 100%;
    overflow-y: scroll;
}

.storesList::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0.625rem;
}

.storesList::-webkit-scrollbar-thumb {
    border-radius: 0.313rem;
    background-color: var(--color-backgroundgray);
}

.storesList::-webkit-scrollbar-track {
    border-radius: 0.313rem;
    background-color: var(--color-lightergray);
}

.checkSection {
    margin-bottom: 0.5rem;
    display: block;
}

.storeQuantitySectionCheck {
    display: grid;
    justify-content: left;
    grid-auto-flow: column;
    gap: 0.5rem;
}

.checkSection span {
    display: block;
    white-space: normal;
    color: var(--color-backgroundgray);
    font-family: var(--fonts-poppins-medium);
    font-size: 0.875rem;
    letter-spacing: 0.028rem;
    text-align: left;
}

.storeContainer {
    height: auto;
    width: 100%;
    max-width: 43.125rem;

    margin-bottom: 1rem;
    padding-right: 1rem;
}

.arrowButton {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}

.storeHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.625rem;
}

.storeHeader > img {
    width: 4.375rem;
    height: 0.938rem;
}

.storeHeader > img.thinkkitchen {
    width: 5.25rem;
    height: 1.185rem;
}

.storeName {
    font-size: 0.875rem;
    color: var(--color-lightgray);
    font-family: var(--fonts-poppins-semibold);
    letter-spacing: 0.049rem;
    margin-bottom: 0.438rem;
    display: flex;
    align-items: center;
}

.storeName:hover {
    text-underline-offset: 0.125rem;
    text-decoration: underline;
}

.location {
    color: var(--color-lightgray);
    font-family: var(--fonts-poppins-regular);
    font-size: 0.75rem;
    letter-spacing: 0.049rem;
    margin-bottom: 0.438rem;
    line-height: 1.25rem;
}

.phone {
    display: block;
    font-size: 0.875rem;
    color: var(--color-lightgray);
    font-family: var(--fonts-poppins-semibold);
    letter-spacing: 0.049rem;
    margin-bottom: 0.25rem;
}

.scheduleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 0.625rem;
    cursor: pointer;
    width: max-content;
    padding-top: 0.5rem;
}


.arrowButton {
    width: 0.938rem;
    height: 0.938rem;
    margin-left: 0.938rem;
    margin-top: 0.125rem;
}

.scheduleTimeContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0.75rem;
    padding-top: 0.5rem;
    min-width: 175px;
}

.dayTimeRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 0.625rem;
    font-family: var(--fonts-poppins-regular);
}

.dayTimeRowToday {
    composes: dayTimeRow;
    color: var(--color-lightgray);
    font-family: var(--fonts-poppins-semibold);
}

.storeActionsContainer {
    display: grid;
    grid-template-columns: repeat(2, min-content) 1fr;
    gap: 1rem;
    align-items: center;
}

.selectThisStoreButton {
    min-width: 12.5rem;
    width: auto;
    white-space: nowrap;
    padding: 0 0.5rem;
    height: 2.5rem;
    color: var(--color-white);
    font-family: var(--fonts-poppins-semibold);
    font-size: 0.875rem;
    letter-spacing: 0.043rem;
    background-color: var(--color-red);
    border-radius: 1.875rem;
}

.getDirectionButton {
    color: var(--color-lightgray);
    font-family: var(--fonts-poppins-regular);
    font-size: 0.875rem;
    letter-spacing: 0.028rem;
    text-underline-offset: 0.125rem;
    text-decoration: underline;
    margin: 0.5rem 0 0.75rem;
    white-space: nowrap;
}

.getDirectionsCompleteButton {
    composes: selectThisStoreButton;
    color: var(--color-gray);
    background-color: white;
    border: 1px solid var(--color-gray);
}

.checkingContainer {
    display: grid;
    grid-template-columns: min-content auto;
    column-gap: 0.5rem;
    align-items: center;
    white-space: nowrap;
    margin: 0.5rem 0 1rem;
}

.checkingContainerLast {
    composes: checkingContainer;
}

.checkingContainer > div {
    display: flex;
    align-items: center;
    margin-right: 0.625rem;
    justify-content: center;
}

.storeActionsContainer > .checkingContainer {
    justify-self: flex-end;
    color: var(--color-darkgray);
    font-family: var(--fonts-poppins-semibold);
    font-size: 0.875rem;
    letter-spacing: 0.028rem;
}

.googleMapContainer {
    background-color: lightseagreen;
    height: 100%;
    width: 100%;
}

.todayLabel {
    text-transform: capitalize;
}
.infoWindow {
    padding-top: 0.625rem;
    padding-left: 0.625rem;
    max-width: 25rem;
    width: auto;
    min-height: 12.5rem;
}

.infoWindow > .name {
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.25rem;
    color: var(--color-lightgray);
    display: flex;
    align-items: center;
}

.infoWindow > .name:hover {
    text-decoration: underline;
    text-underline-offset: 0.125rem;
}

.infoWindow > .address {
    font-family: var(--fonts-poppins-regular);
    font-size: 1rem;
    color: var(--color-lightgray);
}

.infoWindow > .addressCode {
    font-family: var(--fonts-poppins-regular);
    font-size: 1rem;
    color: var(--color-lightgray);
    margin-bottom: 0.9rem;
}

.infoWindow > .phone {
    font-family: var(--fonts-poppins-semibold);
    font-size: 1rem;
    color: var(--color-lightgray);
}

.infoWindow > .dayTimeRowToday {
    margin-top: 0.575rem;
    max-width: 13.75rem;
}

.goBackStoreListButton {
    width: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.875rem;
}

.goBackStoreListButton > img {
    transform: rotate(90deg);
    height: 0.5rem;
    margin-right: 0.625rem;
}

.goBackStoreListButton > div {
    color: var(--color-black);
    font-family: var(--fonts-poppins-regular);
    font-size: 0.875rem;
    letter-spacing: 0.001rem;
    text-underline-offset: 0.125rem;
    text-decoration: underline;
    white-space: nowrap;
}

.storeInfo {
    color: var(--color-graybase);
    max-width: 26.875rem;
    border-bottom: 0.063rem solid var(--color-footergray);
    padding-bottom: 1.375rem;
    margin-bottom: 1.875rem;
}

.storeInfo > img {
    height: 1.063rem;
    width: 5.5rem;
    margin-bottom: 0.938rem;
}

.storeInfo > .storeName {
    font-size: 1.25rem;
    font-family: var(--fonts-poppins-semibold);
    margin-bottom: 0.625rem;
    display: flex;
    align-items: center;
}

.storeInfo > .storeAddress {
    font-family: var(--fonts-poppins-regular);
    font-size: 0.875rem;
    margin-bottom: 0.938rem;
}

.storeInfo > .storePhone {
    font-family: var(--fonts-poppins-semibold);
    font-size: 1rem;
    margin-bottom: 0.625rem;
}

.storeDetailsHoursContainer {
    max-width: 16.25rem;
    color: var(--color-lightgray);
    font-family: var(--fonts-poppins-regular);
    font-size: 0.875rem;
    letter-spacing: 0.043rem;
}

.storeDetailsHoursContainer > .title {
    color: var(--color-lightgray);
    font-family: var(--fonts-poppins-semibold);
    font-size: 1rem;
    letter-spacing: 0.039rem;
    margin-bottom: 1.375rem;
}

.mobileMapContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}



@media screen and (max-width: 1024px) {

    .page {
        padding-left: 0;
        padding-right: 0;
    }

    .pageTitle {
        font-size: 3rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .pageBreadcrumbs {
        margin-left: 1rem;
    }

    .checkingContainerLast {
        margin-top: 1rem;
    }

    .modalCloseBtn {
        box-shadow: 0 0 5px 1px grey;
    }

    .toggleContainer {
        margin-left: 1rem;
        margin-bottom: 1.25rem;
    }

    .storeLocatorModal {
        max-height: none;
        padding: 1rem 0 2.5rem;
        margin: 0;
    }

    .header {
        width: 100%;
        padding: 1rem;
        margin-bottom: 3.25rem;
        align-items: center;
    }

    .header > div {
        font-size: 2rem;
        letter-spacing: 0.097rem;
        margin-bottom: 0;
    }

    .header > button {
        width: 1.25rem;
        height: 1.25rem;
    }

    .inputsContainer {
        flex-direction: column;
        padding: 0 1rem;
    }

    .inputLabelLocation {
        width: 100%;
        margin-bottom: 2.312rem;
        margin-right: 0;
    }

    .inputLabelDistance {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1.5rem;
    }

    .inputKM {
        font-size: 0.75rem;
    }
    .inputKM::placeholder {
        font-size: 0.75rem;
        color: var(--color-base);
    }

    .input {
        font-size: 0.75rem;
    }

    .input::placeholder {
        font-size: 0.75rem;
        color: var(--color-base);
    }

    .findAStoreButton {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1.375rem;
        font-size: 0.75rem;
    }

    .resetButton {
        align-self: center;
        font-size: 0.75rem;
    }


    .listOfStoresLabel {
        margin: 0;
        padding: 1rem;
    }

    .listOfStores {
        margin: 0 0 0.75rem 0;
        text-align: center;
    }

    .seeMapButton {
        text-align: center;
        width: 100%;
        color: var(--color-lightgray);
        font-family: var(--fonts-poppins-regular);
        font-size: 0.75rem;
        letter-spacing: 0.024rem;
        text-underline-offset: 0.125rem;
        text-decoration: underline;
        margin-bottom: 1.563rem;
    }

    .storesMapContainer {
        grid-template-columns: 1fr;
        height: auto;
    }

    .storesContainer {
        padding: 0;
    }

    .storesList {
        width: 100%;
        height: auto;
        overflow-y: hidden;
    }
    .storeContainer {
        height: auto;
        padding: 0 1.25rem 1.25rem 1.25rem;
        max-width: none;
    }

    .storeActionsContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .getDirectionButton {
        margin-bottom: 2.188rem;
    }

    .selectThisStoreButton {
        width: 100%;
    }

    .storeName {
        font-size: 1.25rem;
    }


}

.kmContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: var(--fonts-poppins-semibold);
    font-size: 0.875rem;
}

.distanceValue {
    margin-right: 0.4rem;
    font-family: var(--fonts-poppins-semibold);
    font-size: 0.875rem;
}

.gm-style {
}

.sectionDistance {
    position: relative;
    margin: 0;
}

.openSectionDistance {
    composes: sectionDistance;
}

.itemDistance {
    position: relative;
    display: block;
    width: 100%;
    padding: 0.5rem 2rem;
    background-color: transparent;
    text-align: left;
}

.itemDistanceSelected {
    composes: itemDistance;
    background-color: var(--color-whitegrayish);
}

.itemDistance:hover {
    background-color: var(--color-whitegrayish);
}

.options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin: 1rem 0 0;
    box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.03);
    border-radius: 0.25rem;
    opacity: 0;
    transform-origin: 0 0;
    transform: scaleY(0);
    transition: var(--base-transition);
    background-color: var(--color-white);
    z-index: 2;
    padding: 0;
}
.openSectionDistance .options {
    opacity: 1;
    transform: scaleY(1);
    padding: 0.5rem 0 0;
}

.icon {
    position: absolute;
    right: 1.5rem;
    top: 0.85rem;
}

.indexMarker {
    margin: 0 0.5rem 0 0;
    width: 1.6875rem;
    display: inline-block;
    height: 1.6875rem;
    border-radius: 100%;
    background: var(--color-base);
    color: var(--color-white);
    font-size: 0.75rem;
    line-height: 1.6875rem;
    text-align: center;
    font-family: var(--fonts-poppins-regular);
}

.distanceValue {
    margin-right: 0.4rem;
    font-family: var(--fonts-poppins-semibold);
    font-size: 0.875rem;
}

.bopis {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: max-content auto;
    align-items: center;
    color: var(--color-darkgray);
    font-family: var(--fonts-poppins-semibold);
    font-size: 0.875rem;
    letter-spacing: 0.028rem;
    margin-bottom: 1rem;
}

.paginationContainer {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    grid-gap: 1rem;
    align-items: center;
    padding-top: 0.5rem;
}

.paginationButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    border: 1px solid var(--color-darkgray);
    width: 2rem;
    font-family: var(--fonts-poppins-regular);
    border-radius: 0.5rem;
}

.storesListOuterContainer {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.googleCopyright {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    float: right;
}

.googleCopyright img {
    height: 15px;
}

.googleCopyright p {
    font-size: 9px;
    margin-right: 3px;
}
