.storeScheduleContainer {
    position: absolute;
    left: var(--lower-header-padding-side);
    top: 3rem;
    grid-template-areas: 'header header' 'address time';
    background-color: white;
    width: auto;
    box-shadow: 0 0 0.313rem;
    z-index: 5;
    transition: all 1s;
    display: grid;
}

.init {
    display: none;
}

.openSchedule {
    animation: openSchedule 0.5s forwards;
}

.openSchedule > * {
    animation: SlowAppear 1s forwards;
}

.closeSchedule {
    animation: closeSchedule 0.5s forwards;
}

.closeSchedule > * {
    animation: SlowDisappear 0.1s forwards;
}

@keyframes openSchedule {
    0% {
        display: none;
        height: 0;
    }
    99% {
        height: 28rem;
    }
    100% {
        display: grid;
        height: auto;
        min-height: 28.875rem;
        z-index: 12;
    }
}

@keyframes closeSchedule {
    0% {
        height: 30.313rem;
    }
    99% {
        height: 0.063rem;
    }
    100% {
        height: 0;
        display: none;
        z-index: -5000000;
    }
}

@keyframes SlowAppear {
    0% {
        display: none;
        opacity: 0;
    }
    100% {
        display: grid;
        opacity: 1;
    }
}

@keyframes SlowDisappear {
    0% {
        opacity: 1;
    }
    99% {
        opacity: 0.1;
    }
    100% {
        opacity: 0;
        display: none;
        z-index: -5000000;
    }
}

.StoreScheduleHeader {
    grid-area: header;
    background-color: var(--color-whitegray);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 2.5rem;
    grid-auto-flow: column;
}

.StoreScheduleHeader > img {
    cursor: pointer;
}

.address {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    grid-gap: 0.938rem;
    width: 23rem;
    height: 17.75rem;
    padding: 0 0 1.875rem 3.125rem;
    margin-top: 1.875rem;
    justify-content: start;
    border-right: 0.063rem solid var(--color-lightergray);
}

.address > .name {
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.5rem;
    color: var(--color-lightgray);
    font-weight: bold;
    white-space: initial;
}

.address > .phone {
    font-family: var(--fonts-poppins-semibold);
    font-size: 1rem;
    color: var(--color-lightgray);
    font-weight: bold;
}

.address > .add {
    font-family: var(--fonts-poppins-regular);
    font-size: 1rem;
    color: var(--color-lightgray);
    white-space: initial;
    line-height: 20px;
}

.address .redButton {
    width: auto;
    border-radius: 1.875rem;
    background-color: var(--color-red);
    color: var(--color-white);
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.25rem;
    letter-spacing: 0.056rem;
    padding: 0.938rem 1.5rem;
    min-width: 15rem;
}

.directionButton {
    width: max-content;
    color: var(--color-lightgray);
    font-family: var(--fonts-poppins-regular);
    font-size: 1rem;
    letter-spacing: 0.028rem;
    text-decoration: underline;
}

.timeSchedule {
    display: flex;
    flex-direction: column;
    padding: 1.875rem 2rem 0 2.5rem;
    width: 23rem;
    animation: SlowAppear 1s forwards;
}

.timeHeader {
    background-color: var(--color-lightgray);
    border-radius: 0.313rem;
    color: white;
    font-family: var(--fonts-poppins-semibold);
    letter-spacing: 0.043rem;
    font-size: 0.875rem;
    padding: 1.25rem;
    font-weight: bold;
}

.timeContainer {
    display: grid;
    grid-auto-rows: min-content;
    grid-auto-flow: row;
    grid-gap: 1.125rem;
    padding-top: 1.875rem;
}

.dailyContainer {
    display: flex;
    justify-content: space-between;
}

@media (min-width: 1249px) {
    .address {
        padding: 0 4rem 1.875rem 2.5rem;
    }
}


@media (max-width: 1248px) {

    .storeScheduleContainer {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        overflow-y: auto;
        width: 100%;
        box-shadow: none;
        display: flex;
        flex-direction: column;
    }

    .StoreScheduleHeader{
        display: flex;
        padding: 0.875rem 1.25rem;
        white-space: normal;
    }

    .address{
        margin: 0;
        border-right: none;
        padding: 1.25rem 0 2.5rem 1.25rem;
        border-bottom: 0.125rem solid var(--color-whitegray) ;
        width: 100%;
    }

    .timeSchedule{
        grid-area: time;
        padding: 1.25rem;
        margin: 1.25rem 0 0 0;
        background-color: white;
        width: 100%;
    }

    .directionButton{
        white-space: nowrap;
    }

    .address .redButton {
        min-width: 9.375rem;
        font-size:0.75rem ;
        padding: 0 1.5rem;
        height:2.5rem ;
        font-family: var(--fonts-poppins-semibold);
    }
}
