.cartHeaderContainer {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 2.813rem;
    align-items: center;
}

.accountContainer {
    display: grid;
    grid-template-columns: repeat(2, min-content);
    grid-gap: 10px;
    align-items: center;
    cursor: pointer;
    text-align: start;
}

.accountContainer > img {
    width: 25px;
    height: 25px;
}

.accountLabelContainer {
    display: flex;
    flex-direction: column;
}
.mainLabel {
    color: var(--color-lightgray);
    font-family: var(--fonts-poppins-medium);
    font-size: 1rem;
    white-space: nowrap;
}

.cartContainer {
    position: relative;
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(2, min-content);
    grid-gap: 20px;
    color: var(--color-lightgray);
    font-family: var(--fonts-poppins-medium);
    font-size: 1.25rem;
    letter-spacing: 0.067rem;
}

.cartContainer > img {
    width: 25px;
    height: 25px;
}

.cart_quantity {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 12px;
    top: -15px;
    background-color: var(--color-pink);
    padding: 1px;
    color: var(--color-red);
    border-radius: 50%;
    width: 1.75rem;
    height: 1.75rem;
    font-size: 1rem;
    letter-spacing: 0.049rem;
}
