/* Slider Library */

.root_slider {
    display: block;
    position: relative;
    touch-action: pan-y;
    user-select: none;
    --leftViewportElement: -99999px;
    overflow: hidden;

}
.root_slider :global .slick-current {
    --leftViewportElement: 0;
}

.root_slider :global .slick-slider {
    min-height: 0.063rem;
    text-align: center;
}

.root_slider :global .slick-slider .slick-arrow {
    display: block;
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
    color: white;
    font-size: 0;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 2.5rem;
}

.root_slider :global .slick-slider .slick-prev {
    left: var(--lower-header-padding-side);
}
.root_slider :global .slick-slider .slick-next {
    right: var(--lower-header-padding-side);
}
.root_slider :global .slick-slider .slick-prev:before {
    position: absolute;
    content: '';
    width: 0.6rem;
    height: 0.6rem;
    border-top: 2px solid white;
    border-right: 2px solid white;
    top: 0.938rem;
    left: 0.75rem;
    z-index: 2;
    transform: rotate(-135deg);
}

.root_slider :global .slick-slider .slick-next:before {
    position: absolute;
    content: '';
    width: 0.6rem;
    height: 0.6rem;
    border-top: 2px solid white;
    border-right: 2px solid white;
    top: 0.875rem;
    left: 1rem;
    z-index: 2;
    transform: rotate(45deg);
}

.root_slider :global .slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    min-height: 0.063rem;
    padding-left: 0 !important;
    width: 100%;
}

.root_slider :global .slick-list:focus {
    outline: none;
}

.root_slider :global .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.root_slider :global .slick-track {
    display: block;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0;
    min-height: 0.063rem;
}

.root_slider :global .slick-track .slick-slide > div {
    min-height: 0.063rem;
}

.root_slider :global .slick-track:before,
.root_slider :global .slick-track:after {
    content: '';
    display: table;
}

.root_slider :global .slick-track:after {
    clear: both;
}

:global .slick-loading .slick-track {
    visibility: hidden;
}

.root_slider :global .slick-slide {
    display: none;
    float: left;
    height: auto;
    min-height: 0.063rem;
}
[dir='rtl'] .root_slider :global .slick-slide {
    float: right;
}

.root_slider :global .slick-slide img {
    display: block;
    width: 100%;
    height: auto;
}
.root_slider :global .slick-slide button img {
    margin: auto;
}
.root_slider :global .slick-slide.slick-loading img {
    display: none;
}
.root_slider :global .slick-slide a {
    text-decoration: none;
}

.root_slider :global .slick-slide.dragging img {
    pointer-events: none;
}

:global .slick-initialized .slick-slide {
    display: block;
}

:global .slick-loading .slick-slide {
    visibility: hidden;
}

.root_slider :global .slick-vertical .slick-loading {
    border: 1px solid transparent;
    display: block;
    height: auto;
}

/* end slider  */

.promoBarContainers {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 6rem;
    width: 100%;
}

.promoBarDates {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.promoBarSaveNow {
    display: flex;
    align-items: center;
}
.promoBarSaveNow > a {
    border-bottom: 2px solid var(--color-white);
    font-family: var(--fonts-poppins-regular);
}

.promoBarTitle {
    font-family: var(--fonts-poppins-semibold);
    display: flex;
    align-items: center;
}

.promoBarLine{
    display: flex;
    align-items: center;
}

.promoBarLine,
.promoBarSaveNow,
.promoBarTitle {
    font-size: var(--venia-global-fontSize-600);
    letter-spacing: 0.073rem;
}

.promoBarTime {
    font-size: var(--venia-global-fontSize-700);
    margin-bottom: 0.313rem;
}
.promoBarDays {
    font-size: var(--venia-global-fontSize-75);
}
.promoBarCountdown > span {
    font-weight: var(--venia-global-fontWeight-semibold);
}

.promoBarCountdown > p {
    margin-top: -0.625rem;
    font-size: var(--venia-global-fontSize-75);
}
.promoBarColon {
    align-self: start;
    margin: 0;
    font-family: var(--fonts-poppins-regular);
}
.promoBarColon > span {
    font-weight: 800;
    font-size: 1.75rem;
}
.promoBarWrapper {
    display: grid;
    margin-top: 0.5rem;
    grid-gap: 2rem;
    width: 100%;
    justify-content: flex-end;
    grid-auto-flow: column;
    row-gap: 2rem;
}

.promoBarWrapper > span {
    font-size: var(-spanvenia-global-fontSize-600);
    letter-spacing: 0.073rem;
    margin: 0;
}
.promoBarCountdown {
    display: flex;
    flex-direction: column;
}

.promoBarCountdownContrainer {
    display: grid;
    grid-template-columns: repeat(8, auto);
    grid-gap: 1.3rem;
    align-items: center;
    width: fit-content;
    justify-content: flex-start;
    justify-self: center;
}
.promoBarAfterSale {
    margin-bottom: 0;
    font-size: 1.3rem;
}


@media (max-width: 1248px) {
    .promoBarWrapper {
        row-gap: 1rem;
        align-items: center;
        justify-content: center;
        width: auto;
        padding: 0 2.5rem;
        line-height: 1.4rem;
    }

    .promoBarContainers {
        grid-gap: 0;
        flex-direction: column;
    }
    .promoBarCountdownContrainer {
        grid-gap: 0;
        justify-content: center;
        width: auto;
        display: flex;
    }
    .promoBarCountdown {
        margin: 0 0.7rem;
        position: relative;
        padding: 0 0 0.75rem;
    }
    .promoBarColon {
        align-self: center;
        padding-bottom: 0.75rem;
    }
    .promoBarWrappers > .promoBarTitle,
    .promoBarWrappers > .promoBarSaveNow > a,
    .promoBarWrappers > .promoBarLine {
        display: flex;
        font-size: var(--venia-global-fontSize-75);
        letter-spacing: 0.073rem;
    }

    .promoBarWrapper > .promoBarTitle,
    .promoBarSaveNow > a,
    .promoBarLine {
        display: flex;
        font-size: var(--venia-global-fontSize-300);
        letter-spacing: 0.073rem;
    }
    .promoBarWrapper > h1 {
        font-size: var(--venia-global-fontSize-75);
    }

    .promoBarSaveNow > a {
        border-bottom: 1px solid var(--color-white);
    }
    .promoBarTime {
        font-size: var(--venia-global-fontSize-300);
        margin: 0;
        min-width: 1.35rem;
        text-align: center;
    }
    .promoBarDays {
        font-size: var(--venia-global-fontSize-40);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    .promoBarColon > span {
        padding-bottom: 0.938rem;
        font-size: 1.15rem;
    }

    .root_slider :global .slick-slider .slick-prev {
        left: 0.938rem;
    }
    .root_slider :global .slick-slider .slick-next {
        right: 0.938rem;
    }
}


@media (max-width: 512px) {

    .promoBarWrapper {
        margin-top: 0.15rem;
    }

    .promoBarTitle,
    .promoBarSaveNow a {
        font-size: 0.75rem;
    }
}
