.priceNoDiscount {
    display: flex;
    align-items: center;
    padding: 0 0 0.75rem;
    min-height: 50px;
}

.priceNoDiscount > .priceRegular {
    display: flex;
    align-items: flex-end;
    height: 100%;
    font-size: 1rem;
}

.priceOuterContainer{
    margin-top: 0.5rem;
}

.price {
    font-size: var(--venia-typography-detail-XL-fontSize);
    min-height: 1rem;
}

.price span {
    font-size: 1rem;
}

.discountContainer {
    display: flex;
    align-items: baseline;
    flex-direction: row;
    margin-top: 0.5rem;
}

.discountContainer div {
    margin-right: 0.5rem;
}

.discountContainer > p {
    justify-content: flex-start;
    margin-right: 1.125rem;
    font-family: var(--fonts-poppins-semibold);
    font-size: 1rem;
}

.dicountedInit {
    font-family: var(--fonts-poppins-regular);
    text-decoration: line-through;
    font-weight: 200;
    font-size: 1rem;
}

.notDiscountedInit {
    font-size: 1rem;
    font-family: var(--fonts-poppins-semibold);
}

.discountContainer > .price {
    margin-right: 0.5rem;
    font-size: 1.125rem;
}

.discountContainer > .price >span {
    font-size: 1.125rem;
}


.redCurrency {
    color: var(--color-red);
}

.redInteger {
    color: var(--color-red);
}
.redDecimal {
    color: var(--color-red);
}

.redFraction {
    color: var(--color-red);
}

@media (max-width: 1248px) {
    .price {
        min-height: 85px;
    }
}

@media (max-width: 767px) {
    .priceNoDiscount > .priceRegular {
        font-size: 1rem;
    }
    .price {
        min-height: 2rem;
        padding: 0 0 0.25rem;
    }

    .price span {
        font-size: 14px;
    }

    .discountContainer > p {
        font-size: 12px;
    }
}
