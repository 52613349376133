.field{
    height: 3.75rem;
    border: 0.125rem solid var(--color-lightergray);
    width: 100%;
    font-size: 1.125rem;
    padding: 0 2rem;
    background-color: var(--color-white);
    position: relative;
    text-align: start;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('@magento/venia-ui/venia-static/icons/arrowDropdown.svg');
    background-size: 0.6em;
    background-position: calc(100% - 1.3em) center;
    background-repeat: no-repeat;
}

.field svg{
    position: absolute;
    transition: all 0.5s;
    top: 50%;
    transform:translateY(-50%);
    right: 5%;
    transform-origin: center;
}
.errorField{
    composes:field;
    border-color: var(--color-red);
}

.dropdownContainer{
    position: relative;
}

.fieldOpen{
    composes:field;
}

.fieldOpen svg{
    position: absolute;
    transition: all 0.5s;
    top: 50%;
    transform:translateY(-50%);
    right: 5%;
    transform-origin: center;
}

/* .dropdownMenu{
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    width: 100%;
    align-items: flex-start;
    z-index: 10;
    font-size: 1.125rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    max-height: 200px;
    overflow-y: scroll;
}

.dropdownMenu::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0.625rem;
}

.dropdownMenu::-webkit-scrollbar-thumb {
    border-radius: 0.313rem;
    background-color: var(--color-backgroundgray);
}

.dropdownMenu::-webkit-scrollbar-track {
    border-radius: 0.313rem;
    background-color: var(--color-lightergray);
}

.dropdownMenu > *{
    padding: 0.5rem;
    width: 100%;
    text-align: start;
}

.dropdownMenu > *:hover{
   background-color: var(--color-lightergray);
} */

@media (max-width: 767px) {
    .field{
    
        padding: 0 0.75rem;
    }
    
}
