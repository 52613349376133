.root {
    background-color: var(--color-red);
    outline: none;
    padding: calc(0.5rem + 1px) 1.5rem calc(0.5rem - 1px);
    width: 100%;
    border-radius: 30px;
}

.content {
    align-items: center;
    display: inline-grid;
    gap: 0.35rem;
    grid-auto-flow: column;
    justify-content: center;
    justify-items: center;
    color: var(--color-white);
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.25rem;
}

@media (max-width: 767px) {
    .root {
        min-width: 9.5rem ;
    }
}
