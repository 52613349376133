.giftCardInput input {
    border: 2px solid #ebebeb !important;
    border-radius: 0 !important;
}

.ReCaptcha {
    display: inline-block;
}
.giftCardReset {
    display: block;
    border: none;
    background-color: transparent;
    font-family: var(--fonts-poppins-regular);
    font-size: 1rem;
    text-decoration: underline;
    text-transform: capitalize;
    margin-top: 1rem;
}

.giftCardBanner {
    height: 36rem;
    align-items: center;
    background-size: cover;
    vertical-align: middle;
    width: 100%;
}
.giftCardBannerTitle {
    letter-spacing: 2px;
    font-family: var(--fonts-poppins-semibold);
    margin: 10% 0 2rem 0;
    text-transform: uppercase;
    font-weight: 400;
    color: var(--color-backgroundgray);
    font-size: 2.5rem;
}
.giftCardBannerSubtitle {
    font-family: var(--fonts-poppins-regular);
    line-height: 1.5rem;
    width: 70%;
    color: var(--color-gray);
    font-size: 0.9rem;
}
.ReCaptchaDiv {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding-top: 0.5rem;
    font-family: var(--fonts-poppins-regular);
    color: var(--color-gray);
    font-size: 0.8rem;
}

.ReCaptchaDiv a {
    color: var(--color-bluelink);
}
.whiteBox {
    padding: 4% 0 4% 4%;
    background: white;
    height: 23rem;
    margin: auto;
    position: relative;
    top: 19%;
    left: 20%;
    border-radius: 5px;
    width: 35%;
}

.giftCardFormDiv {
    max-width: 31rem;
    min-width: 30%;
    margin: 8rem auto;
    text-align: center;
}
.giftCardFormDiv h1 {
    margin: 0;
    letter-spacing: 1px;
    font-family: var(--fonts-poppins-semibold), sans-serif;
    color: var(--color-backgroundgray);
    font-size: 1.75rem;
}
.giftCardResponse {
    display: flex;
    font-family: var(--fonts-poppins-regular), sans-serif;
    color: var(--color-backgroundgray);
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
}

.giftCardResponse p {
    margin-left: 0.5rem;
}

.giftCardResponse .value {
    font-family: var(--fonts-poppins-semibold);
}

.giftCardFormButton {
    font-family: var(--fonts-poppins-semibold), sans-serif;
    font-size: 1.2rem;
    background: var(--color-stokes-orange);
    color: white;
    padding: 11px 70px;
    border-radius: 40px;
    outline: none;
    cursor: pointer;
    margin: 2.5rem;
}
.greenCheck {
    margin-right: 0.5rem;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    font-size: 1.2rem;
    height: 20px !important;
    width: 20px !important;
    background-color: #009969;
    border-radius: 50%;
    color: white;
}
.redCheck {
    margin-right: 0.5rem;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    font-size: 1.2rem;
    height: 20px !important;
    width: 20px !important;
    background-color: #c30100;
    border-radius: 50%;
    color: white;
}

.giftCardFormButton:disabled,
.giftCardFormButton[disabled] {
    background-color: #cccccc;
    color: #666666;
}

.balanceMessage {
    font-family: var(--fonts-poppins-semibold), sans-serif;
}
.gitCardBalance {
    font-family: var(--fonts-poppins-semibold), sans-serif;
    font-size: 1.5rem;
    margin: 2rem;
    color: green;
}

.formContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.25rem;
    text-align: start;
}

.button {
    display: flex;
    background-color: var(--color-red);
    font-family: var(--fonts-poppins-semibold);
    height: 60px;
    width: 100%;
    color: var(--color-white);
    border-radius: 1.875rem;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    font-size: 1.25rem;
    max-width: 15.625rem;
    margin-top: 2rem;
    justify-self: center;
}

.messageContainer {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.recaptcha {
    transform: scale(1);
    transform-origin: 0 0;
}

.recaptcha > div > div > div {
    max-width: 12.5rem;
}

.actionsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 768px) {

    .button{
        justify-self: start;
        height:2.5rem ;
        font-size: 0.75rem;
        max-width: 7.5rem;
        margin-top: unset;
    }

    .ReCaptchaDiv {
        text-align: left;
    }
    .giftCardReset {
        display: inline;
        padding-left: 1rem;
        margin-top: unset;
    }
    .whiteBox {
        padding: 3%;
        min-height: 60%;
        top: 30%;
        text-align: center;
        width: 85%;
        left: unset;
    }

    .giftCardBannerTitle {
        letter-spacing: 1px;
        margin: 10% 0 1rem 0;
        font-size: 2.5rem;
    }
    .giftCardBannerSubtitle {
        text-align: center;
        margin: 2rem auto auto auto;
        line-height: 1.5rem;
        width: 50%;
        font-size: 0.9rem;
    }

    .giftCardFormDiv {
        max-width: 85%;
        margin: 5rem auto;
        text-align: left;
        background-color: white;
    }
    .giftCardFormDiv h1 {
        font-size: 1.5rem;
    }
    .giftCardResponse {
        font-size: 1.2rem;
    }

    .giftCardResponse span {
        margin: 0;
    }
    .giftCardFormButton {
        font-size: 0.9rem;
        padding: 10px 45px;
        border-radius: 40px;
        margin: 2.5rem 0 2.5rem 0;
    }
    .actionsContainer{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

}
@media screen and (min-width: 2000px) {
    .giftCardBanner {
        height: 44rem;
    }
    .whiteBox {
        min-height: 28rem;
    }

    .giftCardBannerTitle {
        letter-spacing: 3px;

        font-size: 3.2rem;
    }

    .giftCardBannerSubtitle {
        font-family: var(--fonts-poppins-regular);
        line-height: 2rem;
        font-size: 1.3rem;
    }

    .giftCardFormDiv h1 {
        font-size: 2.3rem;
    }
    .giftCardResponse {
        font-size: 2rem;
    }
    .giftCardFormButton {
        font-size: 1.5rem;
        padding: 12px 70px;
        border-radius: 40px;
        margin: 3rem 0 3rem 0;
    }

    .ReCaptchaDiv {
        padding-top: 0.7rem;
        font-size: 1rem;
    }
}
@media screen and (min-width: 2880px) {
    .giftCardBanner {
        height: 50rem;
    }
    .whiteBox {
        min-height: 32rem;
    }

    .giftCardBannerTitle {
        letter-spacing: 4px;

        font-size: 4rem;
    }
    .giftCardBannerSubtitle {
        font-family: var(--fonts-poppins-regular);
        line-height: 2.2rem;
        font-size: 1.5rem;
    }

    .giftCardFormDiv h1 {
        font-size: 2.5rem;
    }
    .giftCardResponse {
        font-size: 2.2rem;
    }
    .giftCardFormButton {
        font-size: 2rem;
        padding: 13px 90px;
        border-radius: 80px;
        margin: 3.5rem 0 3.5rem 0;
    }

    .ReCaptchaDiv {
        padding-top: 1rem;
        font-size: 1.2rem;
    }
}
