.productContainer {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 2rem;
    padding: 1.5rem 0;
    border-bottom: 0.063rem solid var(--color-footergray);
}

.label{
    color: var(--color-backgroundgray);
    font-family: var(--fonts-poppins-semibold);
    font-size: 1rem;
    line-height: 1.4rem;
    margin-bottom: 0.5rem;
}

.productContainer img {
    width: 11.375rem;
    height: 9.25rem;
}

.productInformation{
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: repeat(2,min-content) 1fr;
}

.qty {
    display: flex;
    align-items: flex-end;
    padding-bottom: 1rem;
}

.notAppliedRules p {
    font-size: 0.75rem;
}

@media (max-width: 1600px) {

    .productContainer {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2,min-content);
        grid-gap: 2rem;
        padding: 1.5rem 0;
        border-bottom: 0.063rem solid var(--color-footergray);
    }

}

@media (max-width: 767px) {
    .productContainer img {
        width: 100px;
        height: 80px;
    }

    .productContainer {
        grid-template-columns: min-content 1fr;
        grid-template-rows: 1fr;
    }
    .qty{
        font-size: 12px;
    }

    .label{
        font-size: 12px;
    }
}
