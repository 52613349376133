.root {
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
}

.root > div > p, .root > div ul li {
    font-size: 1rem;
    color: var(--color-gray);
    line-height: 2rem;
}

.root > div a {
    text-decoration: none;
    font-family: var(--fonts-poppins-semibold);
}


.cmsContent {
    position: relative;
    max-width: 80%;
    margin: 0 auto;
    padding: 0 0 6rem;
}

.cmsContent h1 {
    border-bottom: 1px solid #C7C7C7;
}

.cmsContent div > h1 {
    border-bottom: 0;
}
.cmsContent div > p {
    color: #8E8E8E;
    font-size: 1rem;
    font-family: var(--fonts-poppins-regular);
    margin: 0 0 1.5rem;
    line-height: 180%;
}

.cmsContent div > ul {
    padding: 0 0 0 1.25rem;
}

.cmsContent div > h2 {
    margin-bottom: 1.5rem;
}

.cmsContent div > ul li {
    color: #8E8E8E;
    font-size: 1rem;
    font-family: var(--fonts-poppins-regular);
    margin: 0 0 1.5rem;
    line-height: 180%;
}


@media (max-width: 1024px) {
    .cmsContent {
        position: relative;
        max-width: 100%;
        margin: 0 auto;
        padding: 0 1.5rem 5rem;
    }
    .cmsContent h1 {
        text-align: center;
    }
    .cmsContent div > h2 {
        font-size: 1.125rem;
    }
    .cmsContent div > p,
    .cmsContent div > ul li {
     font-size: 0.75rem;
    }
}
