.faqContainer {
    max-width: 68.75rem;
    margin: 2rem auto;
}

.faqTitle {
    font-family: var(--fonts-poppins-medium);
    font-size: 3rem;
}

.sectionTitle {
    font-family: var(--fonts-poppins-regular);
    margin: 2rem 0;
    font-size: 2rem;
    text-transform: uppercase;
}

.subSectionContainer {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--color-filter);
}

.sectionButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem 0 1rem;
    width: 100%;
    text-align: start;
    font-family: var(--fonts-poppins-regular);
    text-transform: uppercase;
}

.subSectionContainer > p {
    padding:1rem 1rem 0 1.4rem;
    font-family: var(--fonts-poppins-regular);
    line-height: 1.4rem;
}
.textContainer{
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    line-height: 1.4rem;
}

.textContainer >p{
    margin: 0.5rem 0;
    padding-left: 1.4rem;
}

@media (max-width: 676px) {

    .faqTitle {
        margin-left: 1rem;
        font-size: 1.8rem;
    }
    .sectionTitle {
        margin-left: 1rem;
        font-size: 1.4rem;
    }
    .subSectionContainer{
        margin: 0 0.5rem;
    }
}