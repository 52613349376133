.root {
    --leftViewportElement: 0;
}

.contained {
    composes: root;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100%;
}



.videoOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -99;
    -webkit-transform: translate3d(0, 0, 0);
}

.contained div:first-child {
    margin-bottom: 4rem;
}

.contained div div:first-child {
    margin-bottom: 0;
}

.root :global [id*='jarallax-container'] video,
.root :global [id*='jarallax-container'] iframe {
    visibility: hidden;
}

@media only screen and (max-width: 768px) {
    .root {
        background-attachment: scroll !important;
    }

    .contained div:first-child {
        margin-bottom: 3rem;
    }

    .contained div div:first-child {
        margin-bottom: 0;
    }

}
