.root {
    font-size: 0.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    padding: 2.35rem 0;
}
.rootLeft {
    font-size: 0.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    padding: 2.35rem 0;
}

.text {
    padding: 0.35rem;
    font-family: var(--fonts-poppins-medium);
}

.divider {
    composes: text;
}

.link {
    composes: text;
    text-decoration: none;
    color: var(--color-link);
    font-family: var(--fonts-poppins-medium);
    font-size: 1rem;
    line-height: 150%;


}
.link:first-child {
    padding-left: 0;
}

.currentCategory {
    composes: text;
    color: var(--color-current-breadcrumb);
    font-size: 1rem;
}

@media (max-width: 1248px) {
    .root {
        max-width: 100%;
        padding: 1.5rem 1.25rem;
    }
}

@media (max-width: 767px) {
    .link {
        font-size: 0.75rem;
    }
    .currentCategory {
        font-size: 0.75rem;
    }
    .divider {
        font-size: 0.75rem;
        padding: 0.25rem
    }
}

