.imageCounterContainer{
    position: relative;
    display: flex;
    align-items: center;
}

.counter {
    top: -0.8rem;
    right: -0.8rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 600;
    box-sizing: border-box;
    padding: 0 0.25rem;
    border-radius: 100px;
    min-width: 1.25rem;
    font-size: 1rem;
    background-color: var(--color-pink);
    color: var(--color-red);
    width: 28px;
    height: 28px;
}

.trigger {
    align-content: center;
    display: grid;
    height: 3rem;
    justify-content: center;
    position: relative;
    z-index: 1;
    align-items: center;
    color: var(--color-lightgray);
    font-family: var(--fonts-poppins-medium);
    font-size: 1.25rem;
    letter-spacing: 0.067rem;
}

.triggerContainer {
    align-items: center;
    display: grid;
    /* The full height of the site header minus (box shadow height * 2). */
    height: calc(5rem - 8px);
}

.triggerContainer_open {
    composes: triggerContainer;
}

.link {
    composes: trigger;
    display: none;
}



/*
 * Mobile-specific styles.
 */
@media (max-width: 960px) {
    .link {
        display: flex;
    }
}

@media (max-width: 1248px) {
    .counter {
        min-width: none;
        left: unset;
        top: -5px;
        right: -5px;
        height: 20px;
        width: 15px;
        font-size: 0.8rem;
        font-family: var(--fonts-poppins-semibold);
        padding: 0;
    }

    .trigger {
        min-width: 0;
        grid-gap: 0;
    }

    .triggerContainer {
        height: auto;
    }
    .triggerContainer > button {
        height: auto;
    }
}

.modalOverlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    z-index: 99999;
    overflow-y: scroll;
}

.modalOverlay::-webkit-scrollbar {
    width: 0;
    background: transparent;
}
