@font-face {
    font-family: 'poppins-semibold';
    font-display: swap;
    src: url('../venia-static/fonts/Poppins-SemiBold.ttf') format('truetype');
}
@font-face {
    font-family: 'bornready-regular';
    font-display: swap;
    src: url('../venia-static/fonts/BornReady-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'poppins-regular';
    font-display: swap;
    src: url('../venia-static/fonts/Poppins-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'poppins-bold';
    font-display: swap;
    src: url('../venia-static/fonts/Poppins-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'poppins-medium';
    font-display: swap;
    src: url('../venia-static/fonts/Poppins-Medium.ttf') format('truetype');
}

/* global tokens */
:global(:root) {
    /* animation */
    --venia-global-anim-bounce: cubic-bezier(0.5, 1.8, 0.9, 0.8);
    --venia-global-anim-in: cubic-bezier(0, 0, 0.2, 1);
    --venia-global-anim-out: cubic-bezier(0.4, 0, 1, 1);
    --venia-global-anim-standard: cubic-bezier(0.4, 0, 0.2, 1);
    --base-transition: all 0.15s ease-in-out 0s;
    --base-transition2: all 0.25s ease-in-out 0s;
    --base-transition3: all 0.35s ease-in-out 0s;

    /* color */
    --color-white: #ffffff;
    --color-red: #c80003;
    --color-stokes-orange: #c81102;
    --color-blue: #1871ac;
    --color-bluegray: #657988;
    --color-bluelink: #83c7fa;
    --color-whitegray: #f7f7f7;
    --color-gray: #353535;
    --color-darkgray: #353535;
    --color-lightgray: #4b4b4b;
    --color-list: #2A2A34;
    --color-lightergray: #eeeeee;
    --color-backgroundgray: #2e2e2e;
    --color-black: #3c3c3c;
    --color-footergray: #c7c7c7;
    --color-whitegrayish: #f9f9f9;
    --color-pink: #fabcbc;
    --color-green: #009969;
    --color-base: #2e2e2e;
    --color-link: #585858;
    --color-text: #8f908f;
    --color-title: #444444;
    --color-gray-text:#A3A3A3;
    --color-filter: #888888;
    --color-filter-active: #6e6e6e;
    --color-clear-filters: #3c3c3c;
    --color-reviews-content: #999999;
    --color-blue-fb:#3B5A98;
    --color-current-breadcrumb: #888888;
    --color-button-yellow:#FFC43A;
    --color-button-purple:#8333D4;
    --color-dashed-gray:#DCDEE2;
    --color-data: #4E4E4E;
    --color-mounth: #AEAEAE;
    /* fonts */
    --fonts-bornready-regular: 'bornready-regular';
    --fonts-poppins-regular: 'poppins-regular';
    --fonts-poppins-bold: 'poppins-bold';
    --fonts-poppins-medium: 'poppins-medium';
    --fonts-poppins-semibold: 'poppins-semibold';


    /* global paddings */
    --mobile-width: 512px;
    --tablet-width: 1024px;
    --desktop-width: 2880px;
    --header-height: 256px;
    --headerV1-height: 284px;

    /* global size value */
    --stokes-header-logo-width: 14.688rem;
    --middle-header-grid-gap: 2.313rem;
    --middle-header-shopby: 9.375rem;
    --lower-header-padding-side: 4.688rem;

    --venia-global-color-blue-100: 194 200 255;
    --venia-global-color-blue-400: 61 132 255;
    --venia-global-color-blue-500: 51 109 255;
    --venia-global-color-blue-600: 41 84 255;
    --venia-global-color-blue-700: 31 57 255;
    --venia-global-color-blue-800: 23 43 196;
    --venia-global-color-gray-50: 255 255 255;
    --venia-global-color-gray-75: 250 250 250;
    --venia-global-color-gray-100: 244 245 245;
    --venia-global-color-gray-200: 234 235 235;
    --venia-global-color-gray-300: 223 225 226;
    --venia-global-color-gray-400: 181 184 186;
    --venia-global-color-gray-500: 149 154 157;
    --venia-global-color-gray-600: 118 123 127;
    --venia-global-color-gray-700: 84 93 99;
    --venia-global-color-gray-800: 51 63 71;
    --venia-global-color-gray-900: 23 32 38;
    --venia-global-color-gray: var(--venia-global-color-gray-100);
    --venia-global-color-gray-dark: var(--venia-global-color-gray-300);
    --venia-global-color-gray-darker: var(--venia-global-color-gray-600);
    --venia-global-color-green-400: 51 171 132;
    --venia-global-color-green-500: 45 157 120;
    --venia-global-color-green-600: 38 142 108;
    --venia-global-color-green-700: 18 128 92;
    --venia-global-color-orange: 241 99 33;
    --venia-global-color-red-400: 236 91 98;
    --venia-global-color-red-500: 227 72 80;
    --venia-global-color-red-600: 215 55 63;
    --venia-global-color-red-700: 201 37 45;
    --venia-global-color-red-800: 161 30 36;
    --venia-global-color-teal: var(--venia-global-color-blue-400);
    --venia-global-color-teal-dark: var(--venia-global-color-blue-600);
    --venia-global-color-teal-light: var(--venia-global-color-gray-100);

    /* color by semantics */
    --venia-global-color-background: var(--venia-global-color-gray-50);
    --venia-global-color-border: var(--venia-global-color-gray-300);
    --venia-global-color-error: var(--venia-global-color-red-700);
    --venia-global-color-text: var(--venia-global-color-gray-900);
    --venia-global-color-text-alt: var(--venia-global-color-gray-darker);
    --venia-global-color-text-hint: var(--venia-global-color-gray-500);
    --venia-global-color-warning-dark: var(--venia-global-color-red-700);
    --venia-global-color-warning-light: var(--venia-global-color-gray-100);

    /* font family */
    --venia-global-fontFamily-sansSerif: 'Muli', sans-serif;
    --venia-global-fontFamily-serif: 'Source Serif Pro', serif;

    /* font size */
    --venia-global-fontSize-40: 0.625rem; /* 10px */
    --venia-global-fontSize-50: 0.6875rem; /* 11px */
    --venia-global-fontSize-75: 0.75rem; /* 12px */
    --venia-global-fontSize-100: 0.875rem; /* 14px */
    --venia-global-fontSize-200: 1rem; /* 16px */
    --venia-global-fontSize-300: 1.125rem; /* 18px */
    --venia-global-fontSize-400: 1.25rem; /* 20px */
    --venia-global-fontSize-500: 1.375rem; /* 22px */
    --venia-global-fontSize-600: 1.5rem; /* 24px */
    --venia-global-fontSize-700: 1.75rem; /* 28px */
    --venia-global-fontSize-750: 1.875rem; /* 30px */
    --venia-global-fontSize-800: 2rem; /* 32px */
    --venia-global-fontSize-900: 2.25rem; /* 36px */
    --venia-global-fontSize-1000: 2.5rem; /* 40px */
    --venia-global-fontSize-1100: 3rem; /* 48px */
    --venia-global-fontSize-1200: 3.5rem; /* 56px */
    --venia-global-fontSize-1500: 5rem; /* 80px */

    /* font weight */
    --venia-global-fontWeight-normal: 400;
    --venia-global-fontWeight-semibold: 600;
    --venia-global-fontWeight-bold: 700;

    /* line height */
    --venia-global-lineHeight-100: 1;
    --venia-global-lineHeight-200: 1.25;
    --venia-global-lineHeight-300: 1.5;
    --venia-global-lineHeight-400: 1.75;
    --venia-global-lineHeight-500: 2;

    /* dimensions */
    /*--venia-global-maxWidth: 1440px;*/
    --venia-global-header-minHeight: 5rem;
}

@media (max-width: 960px) {
    :global(:root) {
        --venia-global-header-minHeight: 3.5rem;
    }
}

/* alias tokens */
:global(:root) {
    /* colors */
    --venia-brand-color-1-100: var(--venia-global-color-blue-100);
    --venia-brand-color-1-400: var(--venia-global-color-blue-400);
    --venia-brand-color-1-500: var(--venia-global-color-blue-500);
    --venia-brand-color-1-600: var(--venia-global-color-blue-600);
    --venia-brand-color-1-700: var(--venia-global-color-blue-700);
    --venia-brand-color-1-800: var(--venia-global-color-blue-800);

    /* typography */
    /* heading */
    --venia-global-typography-heading-XL-fontSize: var(
        --venia-global-fontSize-600
    );
    --venia-global-typography-heading-L-fontSize: var(
        --venia-global-fontSize-400
    );
    --venia-global-typography-heading-M-fontSize: var(
        --venia-global-fontSize-300
    );
    --venia-global-typography-heading-S-fontSize: var(
        --venia-global-fontSize-200
    );
    --venia-global-typography-heading-XS-fontSize: var(
        --venia-global-fontSize-100
    );
    --venia-global-typography-heading-lineHeight: calc(
        var(--venia-global-lineHeight-200) * 1em
    );

    /* body */
    --venia-typography-body-XXXL-fontSize: var(--venia-global-fontSize-600);
    --venia-typography-body-XXL-fontSize: var(--venia-global-fontSize-500);
    --venia-typography-body-XL-fontSize: var(--venia-global-fontSize-400);
    --venia-typography-body-L-fontSize: var(--venia-global-fontSize-300);
    --venia-typography-body-M-fontSize: var(--venia-global-fontSize-200);
    --venia-typography-body-S-fontSize: var(--venia-global-fontSize-100);
    --venia-typography-body-XS-fontSize: var(--venia-global-fontSize-50);
    --venia-typography-body-lineHeight: calc(
        var(--venia-global-lineHeight-300) * 1em
    );

    /* detail */
    --venia-typography-detail-XL-fontSize: var(--venia-global-fontSize-200);
    --venia-typography-detail-L-fontSize: var(--venia-global-fontSize-100);
    --venia-typography-detail-M-fontSize: var(--venia-global-fontSize-75);
    --venia-typography-detail-S-fontSize: var(--venia-global-fontSize-50);
    --venia-typography-detail-lineHeight: calc(
        var(--venia-global-lineHeight-200) * 1em
    );
}

button {
    background-color: transparent;
    color: currentColor;
    border: 0;
    cursor: pointer;
    outline: none !important;
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-focus: ignore;
    user-select: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

a {
    color: currentColor;
    text-decoration: none;
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent;
    user-select: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

input{
 -webkit-appearance: none;
}

select{
 -webkit-appearance: none;
}
