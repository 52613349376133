.orderConfirmationPage {
    padding: 47px 175px 180px 175px;
}

.orderTitle {
    color: var(--color-backgroundgray);
    font-family: var(--fonts-poppins-semibold);
    font-size: 50px;
    margin-bottom: 54px;
}

.subTitle {
    color: var(--color-clear-filters);
    font-family: var(--fonts-poppins-regular);
    font-size: 1rem;
    max-width: 1220px;
    line-height: 2rem;
    margin-bottom: 50px;
}

.subTitle > a {
    text-decoration: underline;
    text-underline-offset: 0.1rem;
    margin-left: 0.5rem;
}

.subTitleTrack {
    color: var(--color-clear-filters);
    font-family: var(--fonts-poppins-regular);
    font-size: 1rem;
    margin-bottom: 20px;
}

.headerInfo {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 40px;
    border-bottom: 1px solid var(--color-footergray);
    padding-bottom: 24px;
    font-family: var(--fonts-poppins-regular);
    font-size: 24px;
}

.order {
    font-family: var(--fonts-poppins-semibold);
    margin-left: 0.5rem;
    color: var(--color-backgroundgray);
}

.placedOn > span {
    font-family: var(--fonts-poppins-semibold);
    margin-left: 0.5rem;
    color: var(--color-backgroundgray);
}

.actionsContainer{
    display: grid;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    margin-top: 54px ;
    grid-gap: 20px;
}

.orderTotalContainer > .price{

    font-family: var(--fonts-poppins-semibold);
}

.button {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-gap: 1rem;
    align-items: center;
    justify-content: center;
    min-height: 3.5rem;
    border-radius: 1.875rem;
    background-color: var(--color-red);
    color: var(--color-white);
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.25rem;
    width: 300px;
    margin-bottom: 78px;
}

.button:disabled {
    opacity: 0.3;
}

.button > img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
        brightness(102%) contrast(102%);
    margin-bottom: 0.2rem;
}


.whiteButton {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-gap: 1rem;
    align-items: center;
    justify-content: center;
    min-height: 3.5rem;
    border-radius: 1.875rem;
    background-color: var(--color-white);
    color: var(--color-backgroundgray);
    font-family: var(--fonts-poppins-semibold);
    border: 1px solid var(--color-backgroundgray);
    font-size: 1.25rem;
    width: 300px;
    margin-bottom: 78px;
}

.whiteButton:disabled {
    opacity: 0.3;
}

.whiteButton > img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
        brightness(102%) contrast(102%);
    margin-bottom: 0.2rem;
}


.shadowBoxContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 57px;
    margin-bottom: 60px;
}
.shadowBoxContainer> *{
    flex-grow: 1;
}

.shadowContainer {
    padding: 30px 40px;

    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.02);
    min-width: 377px;
}

.shadowContainer > .title {
    font-family: var(--fonts-poppins-semibold);
    font-size: 24px;
    color: var(--color-backgroundgray);
    margin-bottom: 24px;
}

.shadowContainer > .info {
    font-family: var(--fonts-poppins-regular);
    color: var(--color-gray);
    font-size: 16px;
}

.shadowContainer > .info > p {
    margin-bottom: 0.5rem;
}

.products {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    grid-template-columns: 1fr;
}



.product {
    display: grid;
    grid-template-columns: max-content 1fr 1fr  max-content;
    grid-gap: 40px;
    height: 376px;
    border-top: 1px solid var(--color-footergray);
    padding-top: 56px;
}


.product:last-child {
    border-bottom: 1px solid var(--color-footergray);
}


.product > img {
    max-width: 320px;
    height: 260px;
}

.labelSkuContainer {
    color: var(--color-base);
    font-family: var(--fonts-poppins-semibold);
}

.labelSkuContainer .label {
    font-size: 20px;
    margin-bottom: 1rem;
}

.labelSkuContainer .sku {
    font-family: var(--fonts-poppins-regular);
    font-size: 14px;
}



.initialValue {
    font-family: var(--fonts-poppins-semibold);
}

.save {
}

.total {
}

.orderSummaryContainer {
    width: 490px;
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.02);
    padding: 30px 40px;
    height: fit-content;
}



.cartItemsContainer{
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: min-content;
    grid-gap:90px ;
}

.root {
    padding-top: 30px;
}

.errorText {
    color: rgb(var(--venia-global-color-error));
    line-height: var(--venia-global-lineHeight-300);
}

.lineItems {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    line-height: 1.5rem;
    margin-top: 2rem;
}

.lineItems div {
    display: flex;
    justify-content: space-between;
}

.taxContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.taxOuterContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.specificTaxContainer{
display: flex;
flex-direction: column;
width: 100%;
color: var(--color-backgroundgray);
font-family: var(--fonts-poppins-regular);
font-size: 0.75rem;
}

.specificTaxRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.taxContainer button {
    margin-right: 4rem;
}

.rotateUp {
    width: fit-content;
    transform: rotate(0);
    transition: all 0.5s;
}

.rotateDown {
    width: fit-content;
    transform: rotate(180deg);
    transition: all 0.5s;
}

.lineItemLabelDiscount {
    justify-self: start;
    color: var(--color-red);
    font-family: var(--fonts-poppins-regular);
    font-size: 1.25rem;
}

.lineItemLabelDiscount p {
    color: var(--color-red);
    font-family: var(--fonts-poppins-regular);
    font-size: 0.75rem;
}

.priceDiscount {
    color: var(--color-red);
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.25rem;
    text-align: end;
}

.lineItemLabel {
    font-family: var(--fonts-poppins-regular);
    font-size: 1.25rem;
    justify-self: start;
    color: var( --color-backgroundgray);
}

.price {
    font-family: var(--fonts-poppins-regular);
    font-size: 1.25rem;
    color: var( --color-backgroundgray);
    align-items: center;
}

.totalLabel {
    composes: lineItemLabel;
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.25rem;
}

.totalPrice {
    composes: price;
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.25rem;
}

.checkoutButton_container {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    width: 100%;
    margin-top: 1.25rem;
}


.unavailableProductContainer {
    display: grid;
    grid-template-columns: min-content 1fr;
    padding: 20px 40px;
    grid-gap: 0.5rem;
    border: 2px solid var(--color-lightergray);
    font-family: var(--fonts-poppins-regular);
    font-size: 1rem;
    margin-top: 35px;
}

.pricesContainer > .subTotal {
    display: flex;
    border-top: 2px solid var(--color-lightergray);
    padding-top: 0.5rem;
    margin-top: 1rem;
    flex-direction: row;
    justify-content: flex-end;
    font-family: var(--fonts-poppins-semibold);

}

.pricesContainer > .subTotal >p {
   margin-right: 1rem;
}

.pricesContainer > .itemTotal {
    margin-top: 1rem;
    text-align: end;
}
.discountedRegularPrice {
    text-decoration: line-through;
    text-align: end;
}
.discountSaveContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 0.5rem;
}
.savePercent {
    margin-right: 1rem;
    color: var(--color-red);
    font-family: var(--fonts-poppins-semibold);
}
.decimalDiscount {
    color: var(--color-red);
    font-family: var(--fonts-poppins-semibold);
}
.currencyDiscount {
    color: var(--color-red);
    font-family: var(--fonts-poppins-semibold);
}
.fractionDiscount {
    color: var(--color-red);
    font-family: var(--fonts-poppins-semibold);
}
.integerDiscount {
    color: var(--color-red);
    font-family: var(--fonts-poppins-semibold);
}
.discountMinus{
    display: flex;
    flex-direction: row;
    color: var(--color-red);
    font-family: var(--fonts-poppins-semibold);
    margin-right: 0.2rem;
}

.discountMinus div{
    margin-right: 0.2rem;
}



@media (max-width: 1700px) {
    .orderConfirmationPage {
        padding: 47px 20px 100px 20px;
    }

    .shadowContainer{
        width: 100%;
        min-width: unset;
    }

    .orderSummaryContainer{
        width: 100%;
    }

    .cartItemsContainer{
        grid-template-columns: 1fr;
    }

    .actionsContainer{
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        grid-auto-rows: min-content;
        margin-top: 54px ;
    }

    .button{
        margin-bottom: 0;
        width: 100%;
        margin-bottom: 20px;
    }
    .whiteButton{
        margin-bottom: 0;
        width: 100%;
    }

    .headerInfo {
        display: flex;
       flex-wrap: wrap;
        border-bottom: 1px solid var(--color-footergray);
        padding-bottom: 24px;
        font-family: var(--fonts-poppins-regular);
        font-size: 24px;
    }

    .lineItems {
        margin-top: 2rem;
    }

}

.headerContainer{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-footergray);
    color: var(--color-base);
    font-family: var(--fonts-poppins-semibold);
    font-size: 24px;
    padding-bottom: 30px;
    margin-bottom: 30px;
}


@media (max-width: 767px) {

    .headerContainer{
        font-size: 1.125rem;
    }
    .lineItemLabel{
        font-size: 0.875rem;
    }

    .price{
        font-size: 0.875rem;
    }

    .priceDiscount{
        font-size: 0.875rem;
    }

    .lineItemLabelDiscount{
        font-size: 0.875rem;
    }

    .sezzleContainer > img{
        margin: 0 0.5rem;
    }

    .button{
        font-size: 0.875rem;
        height: 40px;
        min-height: unset;
        margin-bottom: 0;
    }
    .whiteButton{
        font-size: 0.875rem;
        height: 40px;
        min-height: unset;

    }

    .orderConfirmationPage .title{
        font-size: 18px;
    }
    .shadowContainer > .title{
        font-size: 18px;
        border: unset;
        margin-bottom: 1rem;
    }

    .shadowContainer > .info{
        font-size: 14px;
    }

    .headerInfo {
     margin-top: 20px;
     font-size: 18px;
    }
    .subTitle{
        font-size: 12px;
    }
    .subTitleTrack{
        font-size: 12px;
    }
    .orderTitle{
        font-size: 35px;
    }

    .lineItems {
        display: grid;
        grid-gap: 1.5rem;
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        grid-auto-rows: min-content;
        line-height: 1.5rem;

        margin-top: 30px;
    }
}

