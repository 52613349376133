/* Styles for "add" view. */
.entryForm,
.entryFormError {
    display: grid;
    grid-template-rows: repeat(2, min-content);
}

.entryForm > div {
    margin: 0;
}

.entryFormError input {
    border-color: rgb(var(--venia-global-color-red-400));
}

.entryFormError input:focus {
    border-color: rgb(var(--venia-global-color-gray-600));
}

.entryFormError p {
    color: rgb(var(--venia-global-color-error));
    font-weight: var(--venia-global-fontWeight-semibold);
}

.errorContainer {
    /* Duplicate the left/right/bottom padding on the accordion section */
    padding-top: 1.5rem;
    color: rgb(var(--venia-global-color-error));
    line-height: var(--venia-global-lineHeight-300);
}

/* on mobile... */
@media (max-width: 960px) {
    .entryForm,
    .entryFormError {
        /* switch to rows. */
        grid-template-columns: unset;
        grid-template-rows: 1fr 1fr;
    }
}

.input {
    width: 100%;
    border-radius: 0;
    appearance: none;
    background-color: var(--color-white);
    height: 60px;
    padding-left: 20px;
    font-size: 16px;
    color: var(--color-text);
    border: 2px solid var(--color-lightergray);
}

.applyButton {
    display: flex;
    align-self: flex-start;
    text-decoration: underline;
    text-underline-offset: 0.1rem;
    color: var(--color-clear-filters);
    font-family: var(--fonts-poppins-regular);
    font-size: 1rem;
    margin-top: 1rem;
}

.insertedCouponContainer{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(2,min-content);
    grid-template-areas: 'code value' 'remove remove';
}

.couponCode{
    grid-area: code;
    color: var(--color-backgroundgray);
    font-family: var(--fonts-poppins-regular);
    font-size: 1.125rem;
}

.valueCode{
    grid-area: value;
    color: var(--color-red);
    font-family: var(--fonts-poppins-regular);
    font-size: 1.125rem;
    text-align: right;
}

.removeButton{
    display: flex;
    grid-area: remove;
    align-self: flex-start;
    color: var(--color-black);
    font-family: var(--fonts-poppins-regular);
    font-size: 1rem;
    text-decoration: underline;
    text-underline-offset: 0.1rem;
    margin-top: 1.5rem;
}