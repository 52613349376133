.container {
    display: grid;
    grid-template-columns: 1rem auto;
    grid-auto-rows: 1fr;
    align-items: center;
    grid-column-gap: 0.5rem;
}

.inverseContainer {
    composes: container;
    grid-template-columns: auto 1rem;
}

.root {
    display: flex;
    position: relative;
    align-items: start;
    justify-content: center;
    height: 1rem;
    width: 1rem;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.875rem;
    height: 0.9375rem;
    z-index: 1;
    margin-top: 1px;
    color: var(--color-white)
}

input[type="checkbox"], input .input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: 1px solid var(--color-base);
    width: 15px;
    height: 15px;
    position: absolute;
    margin: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    border-radius: 0.2rem
}

input[type="checkbox"]:checked, .input:checked {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: black;
}

input[type="checkbox"]:checked .icon, .input:checked .icon{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
}

@media (max-width: 1248px) {
    .root + span {
        font-size: 0.75rem;
    }
}

