

.normalIcon {
    position: relative;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
}

.normalIcon svg {
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.availableIcon {
    position: relative;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background-color: var(--color-green);
}

.availableIcon svg {
    color: var(--color-white);
    width: 80%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.step{
    composes : availableIcon;
    background-color: var(--color-red);
}

.step p {
    color: var(--color-white);
    position: absolute;
    font-size: 0.75rem;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.unstep{
    composes : availableIcon;
    background-color: var(--color-white);
    color: var(--color-clear-filters);
    border: 2px solid var(--color-clear-filters);
}

.unstep p {
    position: absolute;
    font-size: 0.75rem;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-clear-filters);
}

.alertIcon{
    position: relative;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background-color: var(--color-link);
    transform: rotate(180deg);
}

.alertIcon svg {
    color: var(--color-white);
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 1024px) {
    .availableIcon {
        width: 1.125rem;
        height:1.125rem;
    }

    .availableIcon svg {
        color: var(--color-white);
        width: 0.875rem;
        height: 0.875rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

}


@media (max-width: 767px) {
    .availableIcon {
        display: block;
        width:  1rem;
        height: 1rem;
        margin-top: 0;
    }

    .availableIcon svg {
        color: var(--color-white);
        width: 70%;
        height: 70%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

}
