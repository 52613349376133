.middleContainer {
    padding: 46px 0 37px 75px;
    border-bottom: 0.063rem solid var(--color-whitegray);
}

.upperHeader {
    display: flex;
    align-items: center;
    padding: 0.75rem 0 0.5rem 0;

    justify-content: space-between;
    background-color: var(--color-whitegray);
}

.secureCheckout {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: flex-end;
    grid-gap: 0.5rem;
    font-size: 1.125rem;
}

.middleLogo {
    height: fit-content;
    width: fit-content;
    max-width: 18.75rem;
}
.button{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-gap: 1rem;
    align-items: center;
    justify-content: center;
    min-height: 2.5rem;
    border-radius: 1.875rem;
    background-color: var(--color-red);
    color: var(--color-white);
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.25rem;
    padding: 0 10%;
}

@media (max-width: 767px) {

    .button{
        font-size: 0.75rem;
        width: 12.5rem;
        padding:0 20%;
    }

    
    .middleContainer {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1.5rem;
        justify-content: center;
        align-items: center;
        justify-items: center;
        border-bottom: 0.063rem solid var(--color-whitegray);
        padding: 1rem 0;
    }

    .secureCheckout {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        align-items: center;
        grid-gap: 0.5rem;
    }

    .secureCheckout p {
        font-size: 0.75rem;
    }

    .secureCheckout img {
        width: 12px;
        height: 14px;
    }

    .middleLogo {
        height: fit-content;
        max-height: 24px;
        max-width: 115px;
    }

    .upperHeader {
        display: flex;
        padding: 0.75rem 0 0.2rem 0;
        justify-content: center;
        background-color: var(--color-whitegray);
    }
}
