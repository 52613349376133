.root {
    background-color: black;
    cursor: pointer;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition-duration: 192ms;
    transition-property: opacity, visibility;
    transition-timing-function: linear;
    visibility: hidden;
    width: 100%;
    z-index: 2;
    -webkit-appearance: none;
}

/* state: active */

.root_active {
    composes: root;
    opacity: 0.5;
    transition-duration: 224ms;
    visibility: visible;
}
