.iframe {
    width: 100%;
    height: 80vh;
}

.disabledRoot {
    padding: 2rem 1rem;
}

.closeBtn {
    float: right;
}

.popupContent {
    width: 75vw;
}

@media (max-width: 512px) {
    .popupContent {
        width: 95vw;
    }
}
