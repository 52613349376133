.root {
    --stroke: rgb(var(--venia-global-color-text));
    align-items: center;
    display: inline-flex;
    justify-content: center;
    touch-action: manipulation;
}

.icon {
    color: currentColor;
}

@media (min-width: 641px) {
    .icon_desktop {
        width: 28px;
        height: 28px;
    }
}
