.labelInput {
    display: flex;
    flex-direction: column;
}

.labelInput > .label {
    color: var(--color-backgroundgray);
    font-family: var(--fonts-poppins-medium);
    font-size: 0.875rem;
    margin-bottom: 0.875rem;
}

.emptylabel {
    margin-bottom: 1.75rem;
}

.labelInput > .label > span {
    color: var(--color-red);
    font-size: 1rem;
}

.smallRedText {
    margin-top: 0.5rem;
    color: var(--color-red);
    font-family: var(--fonts-poppins-regular);
    font-size: 0.75rem;
}

.errorContainer {
    height: 0.5rem;
}

@media (max-width: 767px) {
    .labelInput > .label {
        font-size: 0.75rem;
    }
}
