.mobileHeader{
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    max-width: var(--desktop-width);
    margin: 0 auto;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    background-color: var(--color-white);
}

.upperHeader{
    background-color: var(--color-whitegray);
    padding: 1rem;
}

.mobileMiddleHeader{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    padding: 0.75rem 1.25rem;
    align-items: center;
}
.mobileMiddleHeader > :nth-child(2){
    display: block;
}

.mobileMiddleHeader > :nth-child(2) img {
    display: block;
    margin: 0 auto;
}
.mobileMiddleHeader > :nth-child(2) img + img {
    margin-top: 0.25rem;
}
.mobileMiddleHeader > div{
    display: flex;
    justify-content: flex-end;
}
.mobileMiddleHeader > div > img{
    margin-left: 0.625rem;
}

.mobileSearch{
    position: relative;
    width: 100%;
    padding: 0.2rem 0.938rem 0.5rem 0.938rem;
}
.mobileSearch input{
    height: 2.5rem;
    font-size: 1rem;
    border-right :none;
}

.accountMenuButton{
    display: flex;
    justify-self: flex-start;
    height:1.563rem;
    width:1.563rem;
}

.middleActionsContainer{
    display: flex;
    align-items: center;
}
