.shippingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.freeShippingMessage {
    font-size: 1rem;
    color: var(--color-title);
    letter-spacing: 0.054rem;
    font-weight: 600;
    text-align: center;
}

.free {
    text-transform: uppercase;
    text-decoration: underline;
    font-family: var(--fonts-poppins-bold);
}
