.root {
    display: inline-grid;
    grid-auto-flow: column;
    grid-template-areas: 'before input after';
    grid-template-columns: auto 1fr auto;
    height: 100%;
    width: 100%;
}

.input {
    align-items: center;
    display: flex;
    grid-column: before-start / after-end;
    grid-row: input-start / input-end;
}

.input > input {
    padding-left: calc(1.875rem * var(--iconsBefore) + 1.5rem);
    padding-right: calc(1.875rem * var(--iconsAfter) + 0.625rem);
}

.before,
.after {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 2px;
    width: 3.438rem;
    z-index: 1;
}

.before:empty,
.after:empty {
    display: none;
}

.before {
    grid-area: before;
}

.after {
    grid-area: after;

}

.after:hover{
    cursor: pointer;
}

.after svg{
height: 20px;
width: 20px;
}

.before svg {
    stroke: rgb(var(--venia-global-color-gray-600));
}

@media screen and (max-width:512px){

}
