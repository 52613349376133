.root {
    display: grid;
    align-content: start;
    margin:1rem 0 1rem 0;
}

.label {
    font-family: var(--fonts-poppins-medium),sans-serif;
    align-items: center;
    color : var(--color-backgroundgray);
    display: flex;
    font-size: .8rem;
    justify-content: space-between;
    line-height: 1.5rem;
    padding: 0.5625rem 0.125rem;
    min-height: 2.125rem;
}

.required::after {
    content: "*";
    display: inline-block;
    color: var(--color-red);
    font-size: 1rem;
}

.input {
    background: white;
    border: 1px solid var(--color-gray);
    border-right: none;
    border-radius: 50px;
    font-family: var(--fonts-poppins-medium),sans-serif;
    color: rgb(var(--venia-global-color-text));
    display: inline-flex;
    flex: 0 0 100%;
    font-size: 1rem;
    height: 100%;
    min-height: 2.813rem;
    max-height: 3.2rem;
    margin: 0;
    max-width: 100%;
    padding: calc(0.375rem - 1px) calc(0.625rem - 1px);
    width: 100%;
    -webkit-appearance: none;
}

.input::placeholder{
    font-size: 1rem;
    color: var(--color-lightgray);
    font-family: var(--fonts-poppins-regular);
}

.input:focus {
    outline: none;
}

.province {
    position: relative;
}



@media screen and (min-width: 2000px) {
    .root {
        margin:1.2rem 0 1.2rem 0;

    }
    .label {
        font-size: 1rem;
        line-height: 1.75rem;

    }
    .input {
        font-size: 1.5rem;
        height: 3.8rem;
    }
}

    @media screen and (min-width: 2880px) {
        .root {
            margin:2rem 0 2rem 0;

        }
        .label {
            font-size: 1.2rem;
            line-height: 2rem;

        }
        .input {
            font-size: 2rem;
            height: 4.5rem;
        }
}


@media screen and (max-width: 512px) {
    .input::placeholder{
        font-size: 0.75rem;
        letter-spacing:0.036rem;
    }
}
