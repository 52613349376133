.field {
    height: 3.75rem;
    border: 0.125rem solid var(--color-lightergray);
    width: 100%;
    font-size: 1.125rem;
    padding: 0 2rem;
}

@media (max-width: 767px) {
    .field {
        padding: 0 0.75rem;
    }
}
