.input {
    composes: input from '../Field/field.css';
}

.input:disabled {
    color: var(--color-darkgray);
}

.input_error {
    composes: input from '../Field/field.css';
    border-color: var(--color-red);
}
