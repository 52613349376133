.root {
    padding-top: 30px;
}

.errorText {
    color: rgb(var(--venia-global-color-error));
    line-height: var(--venia-global-lineHeight-300);
}

.lineItems {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    line-height: 1.5rem;
}

.lineItems div {
    display: flex;
    justify-content: space-between;
}

.taxContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.taxOuterContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.specificTaxContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0.5rem;
}

.specificTaxContainer > div > span.lineItemLabel, .specificTaxContainer > div > span.price {
    color: var(--color-backgroundgray);
    font-family: var(--fonts-poppins-regular);
    font-size: 0.75rem;
}

.specificTaxContainer > div > span.lineItemLabelDiscount, .specificTaxContainer > div > span.priceDiscount {
    color: var(--color-red);
    font-family: var(--fonts-poppins-semibold);
    font-size: 0.75rem;
}

.specificTaxRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-size: 0.75rem;
    padding-top: 0.125rem;
}

.specificTaxRow:last-child{
    padding-top: 0.25rem;
}

.taxContainer button {
    margin-right: 4rem;
    height: 1.5625rem;
}

.rotateUp {
    width: fit-content;
    transform: rotate(0);
    transition: all 0.5s;
}

.rotateDown {
    width: fit-content;
    transform: rotate(180deg);
    transition: all 0.5s;
}

.lineItemLabelDiscount {
    justify-self: start;
    color: var(--color-red);
    font-family: var(--fonts-poppins-regular);
    font-size: 1.25rem;
}

.lineItemLabelDiscount p {
    color: var(--color-red);
    font-family: var(--fonts-poppins-regular);
    font-size: 0.75rem;
}

.labelDiscount {
    color: var(--color-red);
    font-family: var(--fonts-poppins-regular);
    font-size: 0.75rem;
}

.priceDiscount {
    color: var(--color-red);
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.25rem;
    text-align: end;
}

.lineItemLabel {
    font-family: var(--fonts-poppins-regular);
    font-size: 1.25rem;
    justify-self: start;
}

.price {
    font-family: var(--fonts-poppins-regular);
    font-size: 1.25rem;
    align-items: center;
}

.totalLabel {
    composes: lineItemLabel;
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.25rem;
}

.totalPrice {
    composes: price;
    font-family: var(--fonts-poppins-semibold);
    font-size: 1.25rem;
}

.priceUpdating {
    composes: price;
    opacity: 0;
}

.checkoutButton_container {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    width: 100%;
    margin-top: 1.25rem;
}

.checkoutButton_container > button > span {
    display: flex;
    align-items: center;
}

.checkoutButton_container > button > span > img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
        brightness(102%) contrast(102%);
    padding-bottom: 0.3rem;
    margin-right: 0.3rem;
}

.sezzleContainer > p {
    width: 100%;
    justify-content: center;
}

.unavailableProductContainer {
    display: grid;
    grid-template-columns: min-content 1fr;
    padding: 20px 40px;
    grid-gap: 0.5rem;
    border: 2px solid var(--color-lightergray);
    font-family: var(--fonts-poppins-regular);
    font-size: 1rem;
    margin-top: 35px;
}


@media (max-width: 767px) {
    .lineItemLabel{
        font-size: 0.875rem;
    }

    .price{
        font-size: 0.875rem;
    }

    .priceDiscount{
        font-size: 0.875rem;
    }

    .lineItemLabelDiscount{
        font-size: 0.875rem;
    }

    .sezzleContainer > p > span {
        text-align: center;
        line-height: 1.25rem;
    }

    .checkoutButton_container > button > span {
        font-size: 0.75rem;
    }
}
