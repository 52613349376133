.button{
    display: flex;
    background-color: var(--color-red);
    font-family: var(--fonts-poppins-semibold);
    height: 100%;
    width: 100%;
    color:var(--color-white);
    border-radius: 1.875rem;
    align-items: center;
    justify-content: center;
}

.button:disabled{
    opacity: 0.5;
}