.root {
    position: fixed;
    transition: 300ms;
    left: 0;
    width: 100%;
    height: fit-content;
    color: white;
    background-color: var(--color-red);
    bottom: 0;
    z-index: 199;
    font-family: var(--fonts-poppins-regular);

}

.shown {
    left: 0 !important;
}

.body {
    position: relative;
    padding: 1.75rem 5%;
}

.langSection {
    position: absolute;
    padding-bottom: 0.1rem;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
    z-index: 3;
    border-bottom: 1px solid;
    display: none;
}

.closeBtn {
    position: absolute;
    padding: 0.2rem;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 3;
}

.content {
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
}

.policyText {
    margin-right: 0.5rem;
    padding-right: 2rem;
    font-size: 1rem;
    line-height: 1.4;
}

.policyText a {
    text-decoration: underline;
}

.buttons {
    display: grid;
    margin-right: 10%;
}

.button {
    margin: 0.25rem;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    min-width: 8rem;
    font-family: var(--fonts-poppins-regular);
    font-weight: 700;
}

.acceptAllBtn {
    composes: button;
    background-color: white;
    color: var(--color-red);
}

.preferencesBtn {
    composes: button;
    text-decoration: underline;
}

.popupContainer {
    width: 50vw;
    height: fit-content;
    min-height: 20vh;
    background-color: white;
}

.popupBody {
    display: block;
    position: relative;
    padding: 2.5rem;
    font-family: var(--fonts-poppins-regular);
}

.popupCookieGroups {
    height: 320px;
    overflow-y: auto;
}

/* width */
.popupCookieGroups::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.popupCookieGroups::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 2px black;
}

/* Handle */
.popupCookieGroups::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
}

/* Handle on hover */
.popupCookieGroups::-webkit-scrollbar-thumb:hover {
    background: #3c3c3c;
}

.popupTitle {
    font-size: 1.5rem;
    font-weight: 700;
    padding-bottom: 2rem;
    letter-spacing: 0.05rem;
    margin-right: 2rem;
}

.popupCookieGroups input[type="checkbox"] {
    border-width: 2px;
    width: 1rem;
    height: 1rem;
}

.popupCookieGroups input[type="checkbox"]:checked {
    background-color: var(--color-red);
}

.popupCookieGroup {
    padding-bottom: 2rem;
}

.popupGroupTitleSection {
    display: flex;
    flex-direction: row;
}

.popupGroupTitleSection * {
    cursor: pointer;
}

.isNecessary * {
    cursor: not-allowed !important;
}

.popupGroupTitle {
    font-size: 1.25rem;
    font-weight: 700;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.popupGroupDescription {
    padding-top: 0.5rem;
}

.saveBtn {
    composes: button;
    color: white;
    background-color: var(--color-red);
    margin-top: 1.5rem;

}

@media (max-width: 1024px) {

    .body {
        padding-bottom: 0.5rem;
    }

    .langSection {
        display: block;
    }

    .content {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
    }

    .buttons {
        grid-auto-flow: column;
        padding-top: 2rem;

    }

    .policyText {
        padding-right: 0;
        font-size: 0.85rem;
    }

    .popupContainer {
        width: 90vw;
        max-height: 80vh;
    }
}

@media (max-width: 512px) {
    .buttons {
        padding-top: 1rem;
        margin-right: 50%;
    }

    .button {
        font-size: 0.85rem;
        padding: 0.5rem 0.75rem;
    }

    .policyText {
        margin-right: 1rem;
        font-size: 0.75rem;
    }
}
