.root {
    background-color: rgb(var(--venia-global-color-background));
    color: rgb(var(--venia-global-color-text));
    position: relative;
    z-index: 1;
}

.anchorTopButton{
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 5.625rem;
    right: 1.875rem;
    border: 1px solid var(--color-lightergray);
    width: 3.75rem;
    height: 3.75rem;
    color: var(--color-base);
    font-family: var(--fonts-poppins-semibold);
    font-size: 0.75rem;
    z-index: 20;
    background-color: var( --color-white);
}

.mainContent {
    font-family: var(--fonts-poppins-regular);
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: var(--desktop-width);
    overflow: hidden;
}

.overlay {
    position: absolute;
    /* background-color: rgba(0, 0, 0, 0.1); */
    height: 100%;
    width: 100%;
    z-index: 2;

}

.root_masked {
    composes: root;
}

.page {
    margin: 0 auto;
    max-width: var(--desktop-width);
    min-height: 40rem;
    z-index: 5;
}

.page_masked {
    composes: page;
}

.anchorRef{
    position: absolute;
    top: 5%;
    width: 6.25rem;
    left: 0;
    height: 3.25rem;
    z-index: -1;
}


