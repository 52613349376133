.submitModalOverlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    z-index: 11;
    overflow-y: scroll;
}

.submitModalOverlay::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 2rem;
}

.textLayout{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.textContainer {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 2rem;
}

.textContainer span{
    width: 2.2rem;
    height: 2.2rem;
}

.message {
    color: var(--color-base);
    font-family: var(--fonts-poppins-semibold);
    font-size: 36px;
    line-height: 3rem;
}

.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: var(--color-white);
    max-width: 900px;
    max-height: 248px;
    margin: 8.125rem auto;
    padding: 1.375rem 1rem 5rem 1rem;
    line-height: 1.5rem;
}

.containerOpen {
    composes: container;
    animation: containerAnimation 0.5s forwards;
}

@keyframes containerAnimation {
    from {
        transform: translateY(-700px);
    }
    to {
        transform: translateY(0);
    }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 767px) {
    .message {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }

    .textContainer span{
        width: 1.6rem;
        height: 1.6rem;
    }

    .container {
        margin: 8.125rem 1rem;
        padding-bottom: 3rem;
    }

    .header {
        padding-right: 0.5rem;
    }
}
