.root {
    /*
     * For customization, we provide an empty root.
     * These styles will be applied to the image container.
     */
}

.container {
    position: relative;
}

.image {
    /*
     * For customization, we provide an empty image class.
     * These styles will be applied directly to the image itself.
     */
}

.loaded {
    position: absolute;
    top: 0;
    left: 0;
    visibility: visible;
}

.notLoaded {
    composes: loaded;
    visibility: hidden;
}

.placeholder {
    background-color: rgb(var(--venia-global-color-gray));
    position: relative;
    top: 0;
    left: 0;
    max-height: 4rem;
}

.placeholder_layoutOnly {
    composes: placeholder;
    background-color: unset;
}

.imageOnHover {
    opacity: 0;
    transition: 300ms ;
}

.container:hover .imageOnHover {
    opacity: 1;
}
