@value info: rgb(0, 104, 108);
@value warning: rgb(var(--venia-global-color-orange));
@value error: rgb(220, 20, 60);

.root {
    align-items: start;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    color: rgb(33, 33, 33);
    display: grid;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8rem;
    font-weight: lighter;
    gap: 0.5rem 0.75rem;
    grid-template-areas: 'icon message controls';
    grid-auto-columns: min-content auto min-content;
    justify-items: start;
    line-height: 1.25rem;
    margin: 0 auto;
    padding: 1rem;
    width: 20rem;
    border: 1px solid #d1d1d1;
    animation: toast-pulsate 0.5s 1s;
}

@keyframes toast-pulsate {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.icon {
    grid-area: icon;
}

.infoToast {
    composes: root;
    border-bottom: 4px solid info;
}

.infoToast > .icon {
    color: info;
}

.warningToast {
    composes: root;
    border-bottom: 4px solid warning;
}

.warningToast > .icon {
    color: warning;
}

.errorToast {
    composes: root;
    border-bottom: 4px solid error;
}

.errorToast > .icon {
    color: error;
}

.message {
    grid-area: message;
    display: flex;
    font-family: var(--venia-global-fontFamily-sansSerif);
    font-size: 0.875rem;

    /* For wrapping...*/
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
}

.actions {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
}

.controls {
    grid-area: controls;
    border-left: 1px solid rgb(224, 224, 224);
    padding-left: 0.75rem;
}

.actionButton {
    font-weight: 600;
    text-decoration: underline;
    color: rgb(33, 33, 33);
}

.dismissButton {
    color: rgb(112, 112, 112);
}
